package fr.labodoc.app.data.admin.model

import fr.labodoc.domain.labodoc.atcclassification.AtcClassificationCode
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.domain.labodoc.medicine.MedicineName
import io.ktor.http.*

data class MedicineSummaryModel(
  val id: MedicineId,
  val name: MedicineName,
  val atcClassificationCode: AtcClassificationCode,
  val logoUrl: Url,
  val activated: Boolean
)
