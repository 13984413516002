package fr.labodoc.webapp.pages.healthProfessional.laboratories

import fr.labodoc.app.data.healthprofessional.model.LaboratoryModel
import fr.labodoc.app.data.healthprofessional.repository.LaboratoriesRepository
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.components.hr
import fr.labodoc.webapp.components.labodocSpinner
import io.kvision.core.Container
import io.kvision.core.onChange
import io.kvision.form.check.checkBox
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.*
import io.kvision.toast.Toast
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class HealthProfessionalLaboratoriesPage : SimplePanel() {
  private interface ViewModel {
    sealed interface Filter {
      data object All : Filter

      data object Favorites : Filter
    }

    sealed interface LaboratoriesState {
      data object Loading : LaboratoriesState

      data object Error : LaboratoriesState

      data class Loaded(
        val laboratories: ObservableList<LaboratoryModel>
      ) : LaboratoriesState
    }

    val filter: ObservableState<Filter>
    val laboratoriesState: ObservableState<LaboratoriesState>

    fun filterLaboratories(filter: Filter)
    fun setLaboratoryFavoriteStatus(laboratory: LaboratoryModel, favoriteStatus: Boolean)
  }

  private class ViewModelImpl : ViewModel, KoinComponent {
    private val laboratoryRepository: LaboratoriesRepository by inject()

    private val laboratories: MutableList<LaboratoryModel> =
      mutableListOf()

    private val filteredLaboratories: ObservableListWrapper<LaboratoryModel> =
      ObservableListWrapper()

    override val filter: ObservableValue<ViewModel.Filter> =
      ObservableValue(ViewModel.Filter.All)

    override val laboratoriesState: ObservableValue<ViewModel.LaboratoriesState> by lazy {
      val observableValue: ObservableValue<ViewModel.LaboratoriesState> =
        ObservableValue(ViewModel.LaboratoriesState.Loading)

      App.scope.launch {
        laboratoryRepository
          .getLaboratories()
          .onLeft {
            laboratoriesState.setState(ViewModel.LaboratoriesState.Error)
          }
          .onRight {
            laboratories.addAll(it)
            filteredLaboratories.addAll(laboratories)
            laboratoriesState.setState(ViewModel.LaboratoriesState.Loaded(filteredLaboratories))
          }
      }

      observableValue
    }

    private fun updateLaboratory(laboratoryId: LaboratoryId, block: (LaboratoryModel) -> LaboratoryModel) {
      laboratories.apply {
        find { it.id == laboratoryId }?.let { laboratory ->
          set(indexOf(laboratory), block(laboratory))
        }
      }
    }

    override fun filterLaboratories(filter: ViewModel.Filter) {
      App.scope.launch {
        this@ViewModelImpl.filter.setState(filter)

        filteredLaboratories.clear()
        when (filter) {
          ViewModel.Filter.All -> filteredLaboratories.addAll(laboratories)
          ViewModel.Filter.Favorites -> filteredLaboratories.addAll(laboratories.filter { it.favorite })
        }
      }
    }

    override fun setLaboratoryFavoriteStatus(laboratory: LaboratoryModel, favoriteStatus: Boolean) {
      App.scope.launch {
        val response = if (favoriteStatus)
          laboratoryRepository.favorite(laboratory.id)
        else
          laboratoryRepository.unfavorite(laboratory.id)

        response
          .onLeft { Toast.danger("Une erreur est survenue lors de la mise à jour du favori") }
          .onRight { updateLaboratory(laboratory.id) { it.copy(favorite = favoriteStatus) } }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  init {
    id = "page-health-professional-laboratories"
    require("./css/pages/healthProfessional/laboratories/laboratories.css")

    div(className = "page-width") {
      header {
        div(className = "title") {
          h1 {
            content = "Les Laboratoires"
          }

          p {
            content =
              "Accédez facilement aux sites des Laboratoires présents sur LaboDoc !"
          }
        }

        div(className = "filters") {
          div(className = "buttons") {
            button("Tous") {
              onClick { viewModel.filterLaboratories(ViewModel.Filter.All) }
            }.bind(viewModel.filter) { filter ->
              if (filter is ViewModel.Filter.All) addCssClass("active") else removeCssClass("active")
            }

            button("Favoris") {
              onClick { viewModel.filterLaboratories(ViewModel.Filter.Favorites) }
            }.bind(viewModel.filter) { filter ->
              if (filter is ViewModel.Filter.Favorites) addCssClass("active") else removeCssClass("active")
            }
          }
        }
      }

      div(className = "container").bind(viewModel.laboratoriesState) { uiState ->
        when (uiState) {
          ViewModel.LaboratoriesState.Loading -> {
            labodocSpinner()
          }

          ViewModel.LaboratoriesState.Error -> {
            p("Erreur")
          }

          is ViewModel.LaboratoriesState.Loaded -> {
            div(className = "cards").bindEach(uiState.laboratories) { laboratory ->
              link(label = "", url = laboratory.website.value.toString(), target = "_blank") {
                div(className = "card") {
                  header {
                    image(laboratory.logoUrl.toString(), laboratory.name.value)

                    checkBox {
                      addCssClass("favorite")
                      value = laboratory.favorite

                      onChange {
                        viewModel.setLaboratoryFavoriteStatus(laboratory, value)
                      }

                      onClick { it.stopPropagation() }
                    }
                  }

                  hr()

                  p {
                    content = laboratory.name.value
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

fun Container.healthProfessionalLaboratoriesPage(): HealthProfessionalLaboratoriesPage {
  val healthProfessionalLaboratoriesPage = HealthProfessionalLaboratoriesPage()
  this.add(healthProfessionalLaboratoriesPage)
  return healthProfessionalLaboratoriesPage
}
