package fr.labodoc.app.data.healthprofessional.model

import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.laboratory.LaboratoryName
import fr.labodoc.domain.labodoc.laboratory.LaboratoryPharmacovigilance
import fr.labodoc.domain.labodoc.laboratory.LaboratoryWebsite
import fr.labodoc.domain.labodoc.marketingdocument.MarketingDocumentId
import fr.labodoc.domain.labodoc.marketingdocument.MarketingDocumentName
import fr.labodoc.domain.labodoc.medicine.*
import io.ktor.http.*

data class MedicineModel(
  val id: MedicineId,
  val laboratory: Laboratory,
  val name: MedicineName,
  val atcClassification: AtcClassificationModel,
  val dosages: Set<Dosage>,
  val logoUrl: Url,
  val marketingDocuments: Set<MarketingDocument>
) {
  data class Laboratory(
    val id: LaboratoryId,
    val name: LaboratoryName,
    val website: LaboratoryWebsite,
    val pharmacovigilance: LaboratoryPharmacovigilance
  )

  data class MarketingDocument(
    val id: MarketingDocumentId,
    val name: MarketingDocumentName,
    val url: Url,
    val version: Int,
    val latestVersionSeen: Int?
  ) {
    val seenLatestVersion: Boolean = latestVersionSeen == version
  }

  data class Dosage(
    val cisCode: CisCode,
    val name: DosageName
  )
}
