package fr.labodoc.webapp.pages.admin.medicines.list

import fr.labodoc.app.data.admin.model.MedicineSummaryModel
import fr.labodoc.app.data.admin.repository.MedicinesRepository
import fr.labodoc.webapp.App
import io.kvision.state.ObservableListWrapper
import io.kvision.state.ObservableValue
import kotlinx.coroutines.launch

class AdminMedicinesPageViewModelImpl(
  private val medicinesRepository: MedicinesRepository
) : AdminMedicinesPageViewModel() {
  private val medicines: ObservableListWrapper<Medicine> = ObservableListWrapper()

  override val uiState: ObservableValue<UiState> by lazy {
    refresh()
    ObservableValue(UiState.Loading)
  }
  override val errorMessage: ObservableValue<String?> = ObservableValue(null)
  override val successMessage: ObservableValue<String?> = ObservableValue(null)

  override fun refresh() {
    App.scope.launch {
      uiState.value = UiState.Loading
      medicines.clear()

      uiState.value = medicinesRepository
        .getMedicines()
        .map { medicines: Set<MedicineSummaryModel> ->
          medicines.map {
            Medicine(
              id = it.id,
              name = it.name,
              logoUrl = it.logoUrl,
              processing = ObservableValue(false)
            )
          }
        }
        .onRight { medicines: List<Medicine> ->
          this@AdminMedicinesPageViewModelImpl.medicines.addAll(medicines)
        }
        .fold(
          {
            UiState.Error
          },
          {
            UiState.Medicines(
              medicines = medicines
            )
          }
        )
    }
  }

  override fun deleteMedicine(medicine: Medicine) {
    App.scope.launch {
      medicine.processing.value = true

      medicinesRepository
        .deleteMedicine(medicine.id)
        .onLeft {
          medicine.processing.value = false
          errorMessage.value = "Une erreur est survenue lors de la supression de \"${medicine.name.value}\""
        }
        .onRight {
          medicines.remove(medicine)
          successMessage.value = "\"${medicine.name.value}\" correctement supprimé"
        }
    }
  }
}
