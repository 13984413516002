package fr.labodoc.webapp.pages.admin.medicines

import arrow.core.Either
import arrow.core.Nel
import arrow.core.raise.either
import arrow.core.raise.ensureNotNull
import arrow.core.raise.zipOrAccumulate
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.medicine.CisCode
import fr.labodoc.domain.labodoc.medicine.DosageName
import fr.labodoc.webapp.components.LabodocText
import io.kvision.core.Container
import io.kvision.core.StringPair
import io.kvision.form.FormPanel
import io.kvision.form.getDataWithFileContent
import io.kvision.i18n.I18n
import kotlinx.serialization.Serializable

class DosageForm : FormPanel<DosageForm.Data>(serializer = Data.serializer(), className = "dosage-form") {
  @Serializable
  data class Data(
    val cisCode: String? = null,
    val name: String? = null
  ) {
    companion object {
      fun validateCisCode(cisCode: String?): Either<String, CisCode> = either {
        ensureNotNull(cisCode) {
          I18n.tr("Field.Required")
        }

        CisCode(cisCode)
          .mapLeft { error ->
            when (error) {
              Errors.CisCode.Invalid.Format -> "Non valide, doit être composé de 8 chiffres"
            }
          }
          .bind()
      }

      fun validateName(name: String?): Either<String, DosageName> = either {
        ensureNotNull(name) {
          I18n.tr("Field.Required")
        }

        DosageName(name)
          .mapLeft { error ->
            when (error) {
              Errors.Dosage.Name.Invalid.Blank -> "Ne peut pas être vide"
              Errors.Dosage.Name.Invalid.TooLong -> "Trop long, taille maximum ${DosageName.MAX_LENGTH}"
            }
          }
          .bind()
      }
    }
  }

  data class ValidatedData(
    val cisCode: CisCode,
    val name: DosageName
  )

  val cisCodeInput = LabodocText {
    label = "Code CIS"
  }.bindCustom(
    Data::cisCode,
    required = true,
    requiredMessage = I18n.tr("Field.Required"),
    validator = { Data.validateCisCode(it.value).leftOrNull() == null },
    validatorMessage = { Data.validateCisCode(it.value).leftOrNull() }
  )

  val nameInput = LabodocText {
    label = "Nom"
  }.bindCustom(
    Data::name,
    required = true,
    requiredMessage = I18n.tr("Field.Required"),
    validator = { Data.validateName(it.value).leftOrNull() == null },
    validatorMessage = { Data.validateName(it.value).leftOrNull() }
  )

  init {
    add(cisCodeInput)

    add(nameInput)
  }

  suspend fun getValidatedData(
  ): Either<Map<String, String>, ValidatedData> = either<Nel<StringPair>, ValidatedData> {
    val formData = getDataWithFileContent()

    zipOrAccumulate(
      {
        val validation = Data.validateCisCode(formData.cisCode)
        cisCodeInput.validatorError = validation.leftOrNull()

        validation
          .mapLeft { Data::cisCode.name to it }
          .bind()
      },
      {
        val validation = Data.validateName(formData.name)
        nameInput.validatorError = validation.leftOrNull()

        validation
          .mapLeft { Data::name.name to it }
          .bind()
      }
    ) { cisCode, name ->
      ValidatedData(
        cisCode = cisCode,
        name = name
      )
    }
  }.mapLeft { errors ->
    errors.toMap()
  }
}

fun Container.dosageForm(): DosageForm {
  val dosageForm = DosageForm()

  this.add(dosageForm)
  return dosageForm
}
