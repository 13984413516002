package fr.labodoc.webapp.pages.admin.medicines

import arrow.core.Either
import arrow.core.Nel
import arrow.core.raise.either
import arrow.core.raise.ensure
import arrow.core.raise.ensureNotNull
import arrow.core.raise.zipOrAccumulate
import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.domain.healthdirectory.MedicalCardTypeCode
import fr.labodoc.domain.healthdirectory.MedicalCardTypeLabel
import fr.labodoc.domain.healthdirectory.ProfessionalStatusCode
import fr.labodoc.domain.healthdirectory.ProfessionalStatusName
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.InputFile
import fr.labodoc.domain.labodoc.common.Segmentation
import fr.labodoc.domain.labodoc.marketingdocument.MarketingDocumentName
import fr.labodoc.webapp.components.LabodocSegmentation
import fr.labodoc.webapp.components.LabodocText
import fr.labodoc.webapp.components.LabodocUpload
import fr.labodoc.webapp.components.SegmentationAsString
import fr.labodoc.webapp.utils.toInputFile
import io.kvision.core.Container
import io.kvision.core.StringPair
import io.kvision.form.FormPanel
import io.kvision.form.getDataWithFileContent
import io.kvision.form.time.DateTime
import io.kvision.i18n.I18n
import io.kvision.types.KFile
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.toKotlinInstant
import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable
import kotlin.js.Date

class MarketingDocumentForm(
  medicalProfessions: Set<MedicalProfessionModel>,
  professionalStatuses: Set<Pair<ProfessionalStatusCode, ProfessionalStatusName>>,
  medicalCardTypes: Set<Pair<MedicalCardTypeCode, MedicalCardTypeLabel>>,
  fileMandatory: Boolean
) : FormPanel<MarketingDocumentForm.Data>(serializer = Data.serializer(), className = "marketing-document-form") {
  @Serializable
  data class Data(
    val name: String? = null,
    val file: List<KFile>? = null,
    val segmentation: SegmentationAsString? = null,
    @Contextual val expiresAt: Date? = null
  ) {
    companion object {
      fun validateName(name: String?): Either<String, MarketingDocumentName> = either {
        ensureNotNull(name) {
          I18n.tr("Field.Required")
        }

        MarketingDocumentName(name)
          .mapLeft { error ->
            when (error) {
              Errors.MarketingDocument.Name.Invalid.Blank -> "Ne peut pas être vide"
              Errors.MarketingDocument.Name.Invalid.TooLong -> "Trop long, taille maximum ${MarketingDocumentName.MAX_LENGTH}"
            }
          }
          .bind()
      }

      fun validateFile(file: List<KFile>?): Either<String, InputFile> = either {
        ensureNotNull(file?.firstOrNull()?.toInputFile()) {
          I18n.tr("Field.Required")
        }
      }

      fun validateSegmentation(segmentation: Segmentation?): Either<String, Segmentation> = either {
        ensureNotNull(segmentation) {
          I18n.tr("Field.Required")
        }

        ensure(!segmentation.medicalProfessions.isNullOrEmpty() && !segmentation.medicalSpecialities.isNullOrEmpty()) {
          raise("Aucune spécialité renseignée")
        }

        ensure(!segmentation.professionalStatuses.isNullOrEmpty()) {
          raise("Aucune situation professionnelle renseignée")
        }

        ensure(!segmentation.medicalCardTypes.isNullOrEmpty()) {
          raise("Aucun type de carte renseigné")
        }

        segmentation
      }

      fun validateExpiresAt(expireAt: Date?): Either<String, Instant?> = either {
        expireAt?.let {
          ensureNotNull(expireAt) {
            I18n.tr("Field.Required")
          }

          val expireAtFormatted = expireAt.toKotlinInstant()

          ensure(expireAtFormatted > Clock.System.now()) {
            raise("La date d'expiration ne peut pas être inférieure ou égale à maintenant")
          }

          expireAtFormatted
        }
      }
    }
  }

  data class ValidatedCreateData(
      val name: MarketingDocumentName,
      val file: InputFile,
      val segmentation: Segmentation,
      val expiresAt: Instant?
  )

  data class ValidatedUpdateData(
      val name: MarketingDocumentName,
      val file: InputFile?,
      val segmentation: Segmentation,
      val expiresAt: Instant?
  )

  val nameInput = LabodocText {
    label = "Nom"
  }.bindCustom(
    Data::name,
    required = true,
    requiredMessage = I18n.tr("Field.Required"),
    validator = { Data.validateName(it.value).leftOrNull() == null },
    validatorMessage = { Data.validateName(it.value).leftOrNull() }
  )

  val fileInput = LabodocUpload {
    label = "Document"
    accept = listOf(
      "application/pdf"
    )
  }.bind(
    Data::file,
    required = fileMandatory,
    requiredMessage = I18n.tr("Field.Required"),
    validator = null,
    validatorMessage = null
  )

  val segmentationInput = LabodocSegmentation(medicalProfessions, null, professionalStatuses, medicalCardTypes, null) {
    label = "Segmentation du document"
  }.bindCustom(
    Data::segmentation,
    required = true,
    requiredMessage = I18n.tr("Field.Required"),
    validator = { Data.validateSegmentation(it.data).leftOrNull() == null },
    validatorMessage = { Data.validateSegmentation(it.data).leftOrNull() }
  )

  val expireAtInput = DateTime {
    label = "Expire le"
    format = "DD/MM/YYYY HH:mm"
  }.bind(
    key = Data::expiresAt,
    required = false,
    requiredMessage = I18n.tr("Field.Required"),
    validator = { Data.validateExpiresAt(it.value).leftOrNull() == null },
    validatorMessage = { Data.validateExpiresAt(it.value).leftOrNull() }
  )

  init {
    add(nameInput)

    add(fileInput)

    add(segmentationInput)

    add(expireAtInput)
  }

  suspend fun getValidatedCreateData(
  ): Either<Map<String, String>, ValidatedCreateData> = either<Nel<StringPair>, ValidatedCreateData> {
    val formData = getDataWithFileContent()

    zipOrAccumulate(
      {
        val validation = Data.validateName(formData.name)
        nameInput.validatorError = validation.leftOrNull()

        validation
          .mapLeft { Data::name.name to it }
          .bind()
      },
      {
        val validation = Data.validateFile(formData.file)
        fileInput.validatorError = validation.leftOrNull()

        validation
          .mapLeft { Data::file.name to it }
          .bind()
      },
      {
        val validation = Data.validateSegmentation(formData.segmentation)
        segmentationInput.validatorError = validation.leftOrNull()

        validation
          .mapLeft { Data::segmentation.name to it }
          .bind()
      },
      {
        val validation = Data.validateExpiresAt(formData.expiresAt)
        expireAtInput.validatorError = validation.leftOrNull()

        validation
          .mapLeft { Data::expiresAt.name to it }
          .bind()
      }
    ) { name, file, segmentation, expiresAt ->
      ValidatedCreateData(
        name = name,
        file = file,
        segmentation = segmentation,
        expiresAt = expiresAt
      )
    }
  }.mapLeft { errors ->
    errors.toMap()
  }

  suspend fun getValidatedUpdateData(
  ): Either<Map<String, String>, ValidatedUpdateData> = either<Nel<StringPair>, ValidatedUpdateData> {
    val formData = getDataWithFileContent()

    zipOrAccumulate(
      {
        Data.validateName(formData.name)
          .onLeft { nameInput.validatorError = it }
          .mapLeft { Data::name.name to it }
          .bind()
      },
      {
        formData.file?.let {
          Data.validateFile(formData.file)
            .onLeft { fileInput.validatorError = it }
            .mapLeft { Data::file.name to it }
            .bind()
        }
      },
      {
        Data.validateSegmentation(formData.segmentation)
          .onLeft { segmentationInput.validatorError = it }
          .mapLeft { Data::segmentation.name to it }
          .bind()
      },
      {
        Data.validateExpiresAt(formData.expiresAt)
          .onLeft { expireAtInput.validatorError = it }
          .mapLeft { Data::expiresAt.name to it }
          .bind()
      }
    ) { name, file, segmentation, expiresAt ->
      ValidatedUpdateData(
        name = name,
        file = file,
        segmentation = segmentation,
        expiresAt = expiresAt
      )
    }
  }.mapLeft { errors ->
    errors.toMap()
  }
}

fun Container.marketingDocumentForm(
  medicalProfessions: Set<MedicalProfessionModel>,
  professionalStatuses: Set<Pair<ProfessionalStatusCode, ProfessionalStatusName>>,
  medicalCardTypes: Set<Pair<MedicalCardTypeCode, MedicalCardTypeLabel>>,
  fileMandatory: Boolean
): MarketingDocumentForm {
  val marketingDocumentForm = MarketingDocumentForm(
    medicalProfessions,
    professionalStatuses,
    medicalCardTypes,
    fileMandatory
  )

  this.add(marketingDocumentForm)
  return marketingDocumentForm
}
