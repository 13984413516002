package fr.labodoc.domain.labodoc.common

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import fr.labodoc.domain.labodoc.Errors
import kotlin.jvm.JvmInline

/*
 * E.164 standard
 */
@JvmInline
value class PhoneNumber private constructor(val value: String) {
  companion object {
    const val MAX_LENGTH: Int = 50
    val regex = Regex("^\\+[1-9]\\d{1,14}\$")

    fun isFormatValid(value: String): Boolean = regex.containsMatchIn(value)

    operator fun invoke(value: String): Either<Errors.PhoneNumber.Invalid, PhoneNumber> =
      either {
        ensure(isFormatValid(value)) { Errors.PhoneNumber.Invalid.Format }

        PhoneNumber(value)
      }
  }
}
