package fr.labodoc.webapp.components

import fr.labodoc.obj
import fr.labodoc.require
import io.kvision.core.Container
import io.kvision.html.InputType

class LabodocPhone(
  value: String? = null, name: String? = null,
  label: String? = null, rich: Boolean = false, notice: String? = null, noticeRich: Boolean = false,
  init: (LabodocPhone.() -> Unit)? = null
) : LabodocText(
  type = InputType.TEL,
  value = value,
  name = name,
  maxlength = null,
  label = label,
  rich = rich,
  notice = notice,
  noticeRich = noticeRich
) {
  private val intlTelInput: dynamic = require("intl-tel-input/intlTelInputWithUtils")

  private val intlTelInputInstance: dynamic
    get() = intlTelInput.getInstance(input.getElement())

  fun isValid(): Boolean = intlTelInputInstance.isValidNumber() as Boolean
  fun getFormattedNumber(): String? = intlTelInputInstance.getNumber() as String?

  override fun getValue(): String? {
    return getFormattedNumber()
  }

  init {
    require("intl-tel-input/build/css/intlTelInput.css")

    init?.invoke(this)

    addAfterInsertHook {
      intlTelInput(
        input.getElement(),
        obj {
          this.i18n = require("intl-tel-input/i18n").fr
          this.initialCountry = "fr"
        }
      )
    }

    addAfterDestroyHook {
      intlTelInputInstance.destroy()
    }
  }
}

fun Container.labodocPhone(
  value: String? = null, name: String? = null,
  label: String? = null, rich: Boolean = false, notice: String? = null, noticeRich: Boolean = false,
  init: (LabodocPhone.() -> Unit)? = null
): LabodocPhone {
  val labodocPhone = LabodocPhone(
    value = value,
    name = name,
    label = label, rich = rich,
    notice = notice, noticeRich = noticeRich,
    init = init
  )

  this.add(labodocPhone)
  return labodocPhone
}
