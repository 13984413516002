package fr.labodoc.webapp.pages.admin.medicines

import fr.labodoc.webapp.components.LabodocPopup
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.pages.admin.medicines.view.AdminMedicinePageViewModel
import io.kvision.html.h1
import io.kvision.state.ObservableState
import io.kvision.state.bind

class DosageFormModal(
  private val dosage: AdminMedicinePageViewModel.Dosage?,
  onSubmit: (DosageForm) -> Unit,
  processing: ObservableState<Boolean>
) {
  private val dosageForm = DosageForm()

  private val modal = LabodocPopup(
    closeButton = true,
    icon = null,
    image = null,
    className = "admin-medicine-dosage-modal",
    content = {
      h1("Dosage")

      add(dosageForm)

      labodocButton("Sauvegarder", className = "labodoc-background-yellow") {
        onClick {
          onSubmit(dosageForm)
        }
      }.bind(processing) { processing ->
        if (processing) {
          disabled = true
          text = "Traitement"
          icon = "fa fa-spinner fa-spin"
        } else {
          disabled = false
          text = "Sauvegarder"
          icon = null
        }
      }
    })

  fun show() {
    dosageForm.setData(
      DosageForm.Data(
        cisCode = dosage?.cisCode?.value,
        name = dosage?.name?.value,
      )
    )

    modal.show()
  }

  fun hide() {
    modal.hide()
  }
}
