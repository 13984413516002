package fr.labodoc.webapp.pages.admin.medicines

import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.domain.healthdirectory.MedicalCardTypeCode
import fr.labodoc.domain.healthdirectory.MedicalCardTypeLabel
import fr.labodoc.domain.healthdirectory.ProfessionalStatusCode
import fr.labodoc.domain.healthdirectory.ProfessionalStatusName
import fr.labodoc.webapp.components.LabodocPopup
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.pages.admin.medicines.view.AdminMedicinePageViewModel
import io.kvision.html.h1
import io.kvision.state.ObservableState
import io.kvision.state.bind
import kotlinx.datetime.toJSDate

class MarketingDocumentFormModal(
  medicalProfessions: Set<MedicalProfessionModel>,
  professionalStatuses: Set<Pair<ProfessionalStatusCode, ProfessionalStatusName>>,
  medicalCardTypes: Set<Pair<MedicalCardTypeCode, MedicalCardTypeLabel>>,
  private val marketingDocument: AdminMedicinePageViewModel.MarketingDocument?,
  onSubmit: (MarketingDocumentForm) -> Unit,
  processing: ObservableState<Boolean>
) {
  private val marketingDocumentForm = MarketingDocumentForm(
    medicalProfessions = medicalProfessions,
    professionalStatuses = professionalStatuses,
    medicalCardTypes = medicalCardTypes,
    fileMandatory = marketingDocument == null
  )

  private val modal = LabodocPopup(
    closeButton = true,
    icon = null,
    image = null,
    className = "admin-medicine-marketing-document-modal",
    content = {
      h1("Document marketing")

      add(marketingDocumentForm)

      labodocButton("Sauvegarder", className = "labodoc-background-yellow") {
        onClick {
          onSubmit(marketingDocumentForm)
        }
      }.bind(processing) { processing ->
        if (processing) {
          disabled = true
          text = "Traitement"
          icon = "fa fa-spinner fa-spin"
        } else {
          disabled = false
          text = "Sauvegarder"
          icon = null
        }
      }
    })

  fun show() {
    marketingDocumentForm.setData(
      MarketingDocumentForm.Data(
        name = marketingDocument?.name?.value,
        file = null,
        segmentation = marketingDocument?.segmentation,
        expiresAt = marketingDocument?.expireAt?.toJSDate(),
      )
    )

    modal.show()
  }

  fun hide() {
    modal.hide()
  }
}
