package fr.labodoc.app.data.admin.model

import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.laboratory.LaboratoryName
import io.ktor.http.*

data class LaboratorySummaryModel(
  val id: LaboratoryId,
  val name: LaboratoryName,
  val logoUrl: Url,
  val activated: Boolean
)
