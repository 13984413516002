package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import fr.labodoc.domain.labodoc.common.NotificationFrequency
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class AdminUsersResponse(
  val users: Set<User>
) {
  @Serializable
  sealed class User {
    abstract val id: UserIdAsString
    abstract val emailAddress: EmailAddressAsString
    abstract val lastLoginDate: Instant?
    abstract val disabled: Boolean
  }

  @Serializable
  data class Admin(
    override val id: UserIdAsString,
    override val emailAddress: EmailAddressAsString,
    override val lastLoginDate: Instant?,
    override val disabled: Boolean
  ) : User()

  @Serializable
  data class HealthProfessional(
    override val id: UserIdAsString,
    override val emailAddress: EmailAddressAsString,
    override val lastLoginDate: Instant?,
    override val disabled: Boolean,
    val rppsNumber: RPPSNumberAsString?,
    val medicalCardType: MedicalCardTypeCodeAsString?,
    val firstName: FirstNameAsString,
    val lastName: LastNameAsString,
    val department: Department,
    val medicalProfession: MedicalProfession,
    val medicalSpeciality: MedicalSpeciality,
    val medicalInterests: Set<MedicalInterest>,
    val canHaveMedicalInterests: Boolean,
    val professionalCategory: ProfessionalCategory,
    val professionalStatus: ProfessionalStatus,
    val notificationFrequency: NotificationFrequency?
  ) : User() {
    @Serializable
    data class Department(
      val code: DepartmentCodeAsString,
      val name: DepartmentNameAsString
    )

    @Serializable
    data class MedicalProfession(
      val id: MedicalProfessionIdAsString,
      val name: MedicalProfessionNameAsString
    )

    @Serializable
    data class MedicalSpeciality(
      val id: MedicalSpecialityIdAsString,
      val name: MedicalSpecialityNameAsString
    )

    @Serializable
    data class MedicalInterest(
      val id: MedicalInterestIdAsString,
      val name: MedicalInterestNameAsString
    )

    @Serializable
    data class ProfessionalCategory(
      val code: ProfessionalCategoryCodeAsString,
      val name: ProfessionalCategoryNameAsString
    )

    @Serializable
    data class ProfessionalStatus(
      val code: ProfessionalStatusCodeAsString,
      val name: ProfessionalStatusNameAsString
    )
  }
}
