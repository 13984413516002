package fr.labodoc.webapp.pages.healthProfessional.learnedSocieties

import arrow.core.Either
import arrow.core.left
import arrow.core.raise.either
import arrow.core.right
import arrow.fx.coroutines.parZip
import com.benasher44.uuid.uuidFrom
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.app.data.healthprofessional.model.HealthProfessionalUserModel
import fr.labodoc.app.data.healthprofessional.model.LearnedSocietyModel
import fr.labodoc.app.data.healthprofessional.repository.LearnedSocietiesRepository
import fr.labodoc.app.data.healthprofessional.repository.MedicalProfessionsRepository
import fr.labodoc.app.data.healthprofessional.repository.UsersRepository
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyId
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyName
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyWebsite
import fr.labodoc.domain.labodoc.medicalspeciality.MedicalSpecialityId
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.components.LabodocSelectCheckboxInput
import fr.labodoc.webapp.components.hr
import fr.labodoc.webapp.components.labodocSpinner
import io.ktor.http.*
import io.kvision.core.Container
import io.kvision.core.StringPair
import io.kvision.core.onChange
import io.kvision.form.check.checkBox
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.*
import io.kvision.toast.Toast
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class HealthProfessionalLearnedSocietiesPage : SimplePanel() {
  private interface ViewModel {
    data class LearnedSociety(
      val id: LearnedSocietyId,
      val name: LearnedSocietyName,
      val website: LearnedSocietyWebsite,
      val logo: Url,
      val medicalSpecialities: Set<MedicalSpecialityId>,
      var isFavorite: Boolean
    )

    sealed interface Filter {
      data object MySpeciality: Filter

      data object Favorites : Filter

      data object All : Filter

      data class MedicalSpecialities(val medicalSpecialities: Set<MedicalSpecialityId>) : Filter
    }

    sealed interface FavoriteActionResponse {
      val learnedSociety: LearnedSociety
      val response: ApiResponse<Unit>
    }

    data class FavoriteResponse(
      override val learnedSociety: LearnedSociety,
      override val response: ApiResponse<Unit>
    ) : FavoriteActionResponse

    data class UnfavoriteResponse(
      override val learnedSociety: LearnedSociety,
      override val response: ApiResponse<Unit>
    ) : FavoriteActionResponse

    val filter: ObservableState<Filter>

    val medicalSpecialitiesOptionsResponse: ObservableState<Either<ErrorResponse, Map<String?, List<StringPair>>>?>

    val learnedSocietiesResponse: ObservableState<Either<ErrorResponse, Set<LearnedSocietyModel>>?>

    val learnedSocieties: ObservableSet<LearnedSociety>

    val filteredLearnedSocieties: ObservableList<LearnedSociety>

    val favoriteActionResponse: ObservableState<FavoriteActionResponse?>

    fun filterBy(filter: Filter)

    fun favoriteLearnedSociety(learnedSociety: LearnedSociety)

    fun unfavoriteLearnedSociety(learnedSociety: LearnedSociety)
  }

  private class ViewModelImpl : ViewModel, KoinComponent {
    private val userRepository: UsersRepository by inject()
    private val learnedSocietyRepository: LearnedSocietiesRepository by inject()
    private val medicalProfessionRepository: MedicalProfessionsRepository by inject()

    override val filter: ObservableValue<ViewModel.Filter> =
      ObservableValue(ViewModel.Filter.MySpeciality)

    override val medicalSpecialitiesOptionsResponse: ObservableValue<Either<ErrorResponse, Map<String?, List<StringPair>>>?> by lazy {
      val observableValue = ObservableValue<Either<ErrorResponse, Map<String?, List<StringPair>>>?>(null)

      App.scope.launch {
        medicalProfessionRepository
          .getMedicalProfessions()
          .map { medicalProfessions ->
            medicalProfessions.fold(mutableMapOf<String?, List<StringPair>>()) { acc, medicalProfession ->
              acc[medicalProfession.name.value] = medicalProfession.medicalSpecialities.map { medicalSpeciality ->
                medicalSpeciality.id.value.toString() to medicalSpeciality.name.value
              }
              acc
            }
          }
          .also { observableValue.setState(it) }
      }

      observableValue
    }

    override val learnedSocietiesResponse: ObservableValue<Either<ErrorResponse, Set<LearnedSocietyModel>>?> =
      ObservableValue(null)

    override val learnedSocieties: ObservableSet<ViewModel.LearnedSociety> =
      ObservableSetWrapper()

    override val filteredLearnedSocieties: ObservableListWrapper<ViewModel.LearnedSociety> =
      ObservableListWrapper()

    override val favoriteActionResponse: ObservableValue<ViewModel.FavoriteActionResponse?> =
      ObservableValue(null)

    private var selfMedicalSpeciality: MedicalSpecialityId? = null

    init {
      App.scope.launch {
        either {
          parZip(
            {
              userRepository.getSelf().bind()
            },
            {
              learnedSocietyRepository.getLearnedSocieties().bind()
            }
          ) { self, learnedSocieties ->
            if (self is HealthProfessionalUserModel) {
              selfMedicalSpeciality = self.medicalSpeciality.id
              learnedSocietiesResponse.setState(learnedSocieties.right())
            }
          }
        }.onLeft {
          learnedSocietiesResponse.setState(it.left())
        }
      }


      learnedSocietiesResponse.subscribe { learnedSocietiesResponse ->
        learnedSocietiesResponse
          ?.onRight {
            val learnedSocieties = it.map {
              ViewModel.LearnedSociety(
                it.id,
                it.name,
                it.website,
                it.logoUrl,
                it.medicalSpecialities,
                it.favorite
              )
            }.toSet()

            this.learnedSocieties.addAll(learnedSocieties)
          }
      }

      learnedSocieties.subscribe { learnedSocieties ->
        val filter = filter.getState()

        val filteredLearnedSocieties = when (filter) {
          ViewModel.Filter.MySpeciality -> learnedSocieties.filter { it.medicalSpecialities.any { it == selfMedicalSpeciality } }
          ViewModel.Filter.Favorites -> learnedSocieties.filter { it.isFavorite }
          ViewModel.Filter.All -> learnedSocieties
          is ViewModel.Filter.MedicalSpecialities -> learnedSocieties.filter { it.medicalSpecialities.any { it in filter.medicalSpecialities } }
        }

        this.filteredLearnedSocieties.clear()
        this.filteredLearnedSocieties.addAll(filteredLearnedSocieties)
      }

      filter.subscribe { filter ->
        val learnedSocieties = learnedSocieties.getState()

        val filteredLearnedSocieties = when (filter) {
          ViewModel.Filter.MySpeciality -> learnedSocieties.filter { it.medicalSpecialities.any { it == selfMedicalSpeciality } }
          ViewModel.Filter.Favorites -> this.learnedSocieties.filter { it.isFavorite }
          ViewModel.Filter.All -> learnedSocieties
          is ViewModel.Filter.MedicalSpecialities -> this.learnedSocieties.getState().filter { it.medicalSpecialities.any { it in filter.medicalSpecialities } }
        }

        this.filteredLearnedSocieties.clear()
        this.filteredLearnedSocieties.addAll(filteredLearnedSocieties)
      }

      favoriteActionResponse.subscribe { favoriteActionResponse ->
        if (favoriteActionResponse?.response is Either.Right)
          learnedSocieties.find { it.id == favoriteActionResponse.learnedSociety.id }?.isFavorite =
            favoriteActionResponse is ViewModel.FavoriteResponse
      }
    }

    override fun filterBy(filter: ViewModel.Filter) {
      this.filter.setState(filter)
    }

    override fun favoriteLearnedSociety(learnedSociety: ViewModel.LearnedSociety) {
      App.scope.launch {
        learnedSocietyRepository
          .favorite(learnedSociety.id)
          .also { favoriteActionResponse.setState(ViewModel.FavoriteResponse(learnedSociety, it)) }
      }
    }

    override fun unfavoriteLearnedSociety(learnedSociety: ViewModel.LearnedSociety) {
      App.scope.launch {
        learnedSocietyRepository
          .unfavorite(learnedSociety.id)
          .also { favoriteActionResponse.setState(ViewModel.UnfavoriteResponse(learnedSociety, it)) }
      }
    }
  }

  private val viewModel: ViewModel = ViewModelImpl()

  init {
    id = "page-health-professional-learned-societies"
    require("./css/pages/healthProfessional/learnedSocieties/learned-societies.css")

    div(className = "page-width") {
      header {
        div(className = "title") {
          h1 {
            content = "Les Sociétés Savantes, Clubs et Associations"
          }

          p {
            content =
              "Accédez facilement aux sites depuis notre annuaire !"
          }
        }

        val medicalSpecialitiesFilter = LabodocSelectCheckboxInput {
          onChange {
            viewModel.filterBy(
              ViewModel.Filter.MedicalSpecialities(
                this.values?.map { MedicalSpecialityId(uuidFrom(it)) }?.toSet().orEmpty()
              )
            )
          }

          viewModel.medicalSpecialitiesOptionsResponse.subscribe { medicalSpecialitiesOptionsResponse ->
            options = when (medicalSpecialitiesOptionsResponse) {
              is Either.Left -> TODO("Error handling")
              is Either.Right -> medicalSpecialitiesOptionsResponse.value
              null -> null
            }
          }

          viewModel.filter.subscribe { filter ->
            when (filter) {
              is ViewModel.Filter.MedicalSpecialities -> this.show()
              else -> this.hide()
            }
          }
        }

        div(className = "filters") {
          div(className = "buttons") {
            button("Ma spécialité") {
              onClick { viewModel.filterBy(ViewModel.Filter.MySpeciality) }
            }.bind(viewModel.filter) { filter ->
              if (filter is ViewModel.Filter.MySpeciality) addCssClass("active") else removeCssClass("active")
            }
            button("Favoris") {
              onClick { viewModel.filterBy(ViewModel.Filter.Favorites) }
            }.bind(viewModel.filter) { filter ->
              if (filter is ViewModel.Filter.Favorites) addCssClass("active") else removeCssClass("active")
            }
            button("Tous") {
              onClick { viewModel.filterBy(ViewModel.Filter.All) }
            }.bind(viewModel.filter) { filter ->
              if (filter is ViewModel.Filter.All) addCssClass("active") else removeCssClass("active")
            }
            button("Spécialité") {
              onClick {
                viewModel.filterBy(
                  ViewModel.Filter.MedicalSpecialities(
                    medicalSpecialitiesFilter.values?.map { MedicalSpecialityId(uuidFrom(it)) }?.toSet().orEmpty()
                  )
                )
              }
            }.bind(viewModel.filter) { filter ->
              if (filter is ViewModel.Filter.MedicalSpecialities) addCssClass("active") else removeCssClass("active")
            }
          }

          add(medicalSpecialitiesFilter)
        }
      }

      div(className = "container").bind(viewModel.learnedSocietiesResponse) { learnedSocietiesResponse ->
        when (learnedSocietiesResponse) {
          is Either.Left -> {
            Toast.danger("Impossible de récupérer la liste des sociétés savantes")
            window.history.back()
          }

          is Either.Right -> {
            div(className = "cards").bindEach(viewModel.filteredLearnedSocieties) { learnedSociety ->
              link(label = "", url = learnedSociety.website.value.toString(), target = "_blank") {
                div(className = "card") {
                  header {
                    image(learnedSociety.logo.toString(), learnedSociety.name.value)

                    checkBox {
                      addCssClass("favorite")
                      value = learnedSociety.isFavorite

                      onChange {
                        when (value) {
                          true -> viewModel.favoriteLearnedSociety(learnedSociety)

                          false -> viewModel.unfavoriteLearnedSociety(learnedSociety)
                        }
                      }

                      onClick { it.stopPropagation() }
                    }
                  }

                  hr()

                  p {
                    content = learnedSociety.name.value
                  }
                }
              }
            }
          }

          null -> labodocSpinner()
        }
      }
    }
  }
}

fun Container.healthProfessionalLearnedSocietiesPage(): HealthProfessionalLearnedSocietiesPage {
  val healthProfessionalLearnedSocietiesPage = HealthProfessionalLearnedSocietiesPage()
  this.add(healthProfessionalLearnedSocietiesPage)
  return healthProfessionalLearnedSocietiesPage
}
