package fr.labodoc.webapp.pages.admin.medicines.list

import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.domain.labodoc.medicine.MedicineName
import io.ktor.http.*
import io.kvision.state.ObservableList
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue

abstract class AdminMedicinesPageViewModel {
  sealed class UiState {
    data object Loading : UiState()

    data object Error : UiState()

    data class Medicines(
      val medicines: ObservableList<Medicine>
    ) : UiState()
  }

  data class Medicine(
    val id: MedicineId,
    val name: MedicineName,
    val logoUrl: Url,
    val processing: ObservableValue<Boolean>
  )

  abstract val uiState: ObservableState<UiState>
  abstract val errorMessage: ObservableState<String?>
  abstract val successMessage: ObservableState<String?>

  abstract fun refresh()

  abstract fun deleteMedicine(medicine: Medicine)
}
