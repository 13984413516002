package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.medicine.*
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object MedicineIdAsStringSerializer : KSerializer<MedicineId> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("MedicineIdAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(id: String): Either<Errors.Medicine.Id.Invalid, MedicineId> =
    UuidAsStringSerializer.deserialize(id)
      .mapLeft { Errors.Medicine.Id.Invalid.Format }
      .map { MedicineId(it) }

  override fun deserialize(decoder: Decoder): MedicineId {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(id: MedicineId): String = UuidAsStringSerializer.serialize(id.value)

  override fun serialize(encoder: Encoder, value: MedicineId) {
    return encoder.encodeString(serialize(value))
  }
}

typealias MedicineIdAsString = @Serializable(MedicineIdAsStringSerializer::class) MedicineId


object MedicineNameAsStringSerializer : KSerializer<MedicineName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("MedicineNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(name: String): Either<Errors.Medicine.Name.Invalid, MedicineName> =
    MedicineName(name)

  override fun deserialize(decoder: Decoder): MedicineName =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )


  fun serialize(name: MedicineName): String =
    name.value

  override fun serialize(encoder: Encoder, value: MedicineName) =
    encoder.encodeString(serialize(value))
}

typealias MedicineNameAsString = @Serializable(MedicineNameAsStringSerializer::class) MedicineName


object MedicineWebsiteAsStringSerializer : KSerializer<MedicineWebsite> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("MedicineWebsiteAsStringSerializer", PrimitiveKind.STRING)

  override fun deserialize(decoder: Decoder): MedicineWebsite =
    decoder.decodeSerializableValue(UrlAsStringSerializer).let { url ->
      MedicineWebsite(url)
    }

  override fun serialize(encoder: Encoder, value: MedicineWebsite) =
    encoder.encodeSerializableValue(UrlAsStringSerializer, value.value)
}

typealias MedicineWebsiteAsString = @Serializable(MedicineWebsiteAsStringSerializer::class) MedicineWebsite
