package fr.labodoc.webapp.pages.admin.medicines.create

import fr.labodoc.app.data.admin.model.LaboratorySummaryModel
import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.webapp.pages.admin.medicines.AdminMedicineForm
import io.kvision.state.ObservableState

abstract class AdminMedicineCreatePageViewModel {
  sealed class UiState {
    data object Loading : UiState()

    data object Error : UiState()

    data class Form(
      val laboratories: Set<LaboratorySummaryModel>,
      val medicalProfessions: Set<MedicalProfessionModel>,
      val formData: AdminMedicineForm.Data,
      val processing: ObservableState<Boolean>
    ) : UiState()

    data class MedicineCreated(
      val id: MedicineId
    ) : UiState()
  }


  abstract val uiState: ObservableState<UiState>
  abstract val errorMessage: ObservableState<String?>
  abstract val successMessage: ObservableState<String?>

  abstract fun refresh()

  abstract fun createMedicine(
    form: AdminMedicineForm
  )
}
