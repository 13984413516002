package fr.labodoc.app.data.admin.repository

import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.requests.LoginRequest
import fr.labodoc.api.payloads.responses.AdminUsersResponse
import fr.labodoc.app.data.admin.model.AdminUserModel
import fr.labodoc.app.data.admin.model.HealthProfessionalUserModel
import fr.labodoc.app.data.admin.model.UserModel
import fr.labodoc.domain.labodoc.common.EmailAddress
import fr.labodoc.domain.labodoc.common.Password
import fr.labodoc.domain.labodoc.user.UserId

class UsersRepositoryImpl(
  private val apiClient: AdminApiClient
) : UsersRepository {
  override suspend fun getUsers(
  ): ApiResponse<Set<UserModel>> =
    apiClient.users
      .getUsers()
      .map { usersResponse ->
        usersResponse.users
          .map { user ->
            when (user) {
              is AdminUsersResponse.Admin -> AdminUserModel(
                id = user.id,
                emailAddress = user.emailAddress,
                lastLoginDate = user.lastLoginDate,
                disabled = user.disabled
              )

              is AdminUsersResponse.HealthProfessional -> HealthProfessionalUserModel(
                id = user.id,
                emailAddress = user.emailAddress,
                lastLoginDate = user.lastLoginDate,
                disabled = user.disabled,
                rppsNumber = user.rppsNumber,
                medicalCardType = user.medicalCardType,
                firstName = user.firstName,
                lastName = user.lastName,
                department = HealthProfessionalUserModel.Department(
                  code = user.department.code,
                  name = user.department.name
                ),
                medicalProfession = HealthProfessionalUserModel.MedicalProfession(
                  id = user.medicalProfession.id,
                  name = user.medicalProfession.name
                ),
                medicalSpeciality = HealthProfessionalUserModel.MedicalSpeciality(
                  id = user.medicalSpeciality.id,
                  name = user.medicalSpeciality.name
                ),
                medicalInterests = user.medicalInterests
                  .map { medicalInterest ->
                    HealthProfessionalUserModel.MedicalInterest(
                      id = medicalInterest.id,
                      name = medicalInterest.name
                    )
                  }
                  .toSet(),
                canHaveMedicalInterests = user.canHaveMedicalInterests,
                professionalCategory = HealthProfessionalUserModel.ProfessionalCategory(
                  code = user.professionalCategory.code,
                  name = user.professionalCategory.name
                ),
                professionalStatus = HealthProfessionalUserModel.ProfessionalStatus(
                  code = user.professionalStatus.code,
                  name = user.professionalStatus.name
                ),
                notificationFrequency = user.notificationFrequency
              )
            }
          }
          .toSet()
      }

  override suspend fun enableUser(
    id: UserId
  ): ApiResponse<Unit> =
    apiClient.users
      .enableUser(id)

  override suspend fun disableUser(
    id: UserId
  ): ApiResponse<Unit> =
    apiClient.users
      .disableUser(id)

  override suspend fun deleteUser(
    id: UserId
  ): ApiResponse<Unit> =
    apiClient.users
      .deleteUser(id)

  override suspend fun login(
    email: EmailAddress,
    password: Password
  ): ApiResponse<String> =
    apiClient.users
      .login(LoginRequest(email, password))
      .map { it.token }

  override suspend fun logout(
  ): ApiResponse<Unit> =
    apiClient.users
      .logout()
}
