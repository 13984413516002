package fr.labodoc.domain.labodoc.atcclassification

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import fr.labodoc.domain.labodoc.Errors
import kotlin.jvm.JvmInline

@JvmInline
value class AtcClassificationName private constructor(val value: String) {
  companion object {
    const val MAX_LENGTH: Int = 255

    fun isNotTooLong(value: String): Boolean = value.length <= MAX_LENGTH

    fun isNotBlank(value: String): Boolean = value.isNotBlank()

    operator fun invoke(value: String): Either<Errors.AtcClassification.Name.Invalid, AtcClassificationName> =
      either {
        ensure(isNotTooLong(value)) { Errors.AtcClassification.Name.Invalid.TooLong }
        ensure(isNotBlank(value)) { Errors.AtcClassification.Name.Invalid.Blank }

        AtcClassificationName(value)
      }
  }
}
