package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.medicine.CisCode
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object CisCodeAsStringSerializer : KSerializer<CisCode> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("CisCodeAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(cisCode: String): Either<Errors.CisCode.Invalid, CisCode> = CisCode(cisCode)

  override fun deserialize(decoder: Decoder): CisCode {
    return deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )
  }

  fun serialize(cisCode: CisCode): String = cisCode.value

  override fun serialize(encoder: Encoder, value: CisCode) {
    return encoder.encodeString(serialize(value))
  }
}

typealias CisCodeAsString = @Serializable(CisCodeAsStringSerializer::class) CisCode
