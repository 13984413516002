package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.medicine.DosageName
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object DosageNameAsStringSerializer : KSerializer<DosageName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("DosageNameAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(name: String): Either<Errors.Dosage.Name.Invalid, DosageName> =
    DosageName(name)

  override fun deserialize(decoder: Decoder): DosageName =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )


  fun serialize(name: DosageName): String =
    name.value

  override fun serialize(encoder: Encoder, value: DosageName) =
    encoder.encodeString(serialize(value))
}

typealias DosageNameAsString = @Serializable(DosageNameAsStringSerializer::class) DosageName
