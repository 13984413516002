package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.responses.*
import fr.labodoc.api.resources.Medicines
import fr.labodoc.api.routes.HealthProfessionalMedicinesRoutes
import fr.labodoc.domain.labodoc.marketingdocument.MarketingDocumentId
import fr.labodoc.domain.labodoc.medicine.MedicineId
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.http.*

class HealthProfessionalMedicinesRemoteDataSource(
  private val httpClient: HttpClient
) : HealthProfessionalMedicinesRoutes {
  override suspend fun getMedicines(
  ): ApiResponse<HealthProfessionalMedicinesSummaryResponse> {
    val response = httpClient.get(Medicines.ViewList())

    return if (response.status.isSuccess())
      response.body<HealthProfessionalMedicinesSummaryResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getSegmentedMedicines(
  ): ApiResponse<HealthProfessionalMedicinesSummaryResponse> {
    val response = httpClient.get(Medicines.UsageList())

    return if (response.status.isSuccess())
      response.body<HealthProfessionalMedicinesSummaryResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getMedicine(
    id: MedicineId
  ): ApiResponse<HealthProfessionalMedicineWithInformationResponse> {
    val response = httpClient.get(Medicines.Medicine(id))

    return if (response.status.isSuccess())
      response.body<HealthProfessionalMedicineWithInformationResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getMarketingDocuments(
    forMedicine: MedicineId
  ): ApiResponse<HealthProfessionalMarketingDocumentsResponse> {
    val response = httpClient.get(Medicines.Medicine.MarketingDocuments(forMedicine))

    return if (response.status.isSuccess())
      response.body<HealthProfessionalMarketingDocumentsResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getMarketingDocument(
      id: MarketingDocumentId,
      forMedicine: MedicineId
  ): ApiResponse<HealthProfessionalMarketingDocumentResponse> {
    val response = httpClient.get(Medicines.Medicine.MarketingDocuments.MarketingDocument(forMedicine, id))

    return if (response.status.isSuccess())
      response.body<HealthProfessionalMarketingDocumentResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getMessages(
    id: MedicineId
  ): ApiResponse<Set<MessageSummaryResponse>> {
    val response = httpClient.get(Medicines.Medicine.Messages(id))

    return if (response.status.isSuccess())
      response.body<Set<MessageSummaryResponse>>().right()
    else
      response.body<ErrorResponse>().left()
  }
}
