package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import kotlinx.serialization.Serializable

@Serializable
data class HealthProfessionalMedicineWithInformationResponse(
  val medicine: Medicine
) {
  @Serializable
  data class Medicine(
    val id: MedicineIdAsString,
    val laboratory: Laboratory,
    val name: MedicineNameAsString,
    val atcClassification: AtcClassification,
    val logoURL: UrlAsString,
    val dosages: Set<Dosage>,
    val marketingDocuments: Set<MarketingDocument>
  ) {
    @Serializable
    data class Laboratory(
      val id: LaboratoryIdAsString,
      val name: LaboratoryNameAsString,
      val website: LaboratoryWebsiteAsString,
      val pharmacovigilance: LaboratoryPharmacovigilanceAsString
    )

    @Serializable
    data class AtcClassification(
        val code: AtcClassificationCodeAsString,
        val name: AtcClassificationLabelAsString,
        val parent: AtcClassification?
    )

    @Serializable
    data class Dosage(
      val cisCode: CisCodeAsString,
      val name: DosageNameAsString
    )

    @Serializable
    data class MarketingDocument(
      val id: MarketingDocumentIdAsString,
      val name: MarketingDocumentNameAsString,
      val documentUrl: UrlAsString,
      val version: Int,
      val lastVersionSeen: Int?
    )
  }
}
