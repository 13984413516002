package fr.labodoc.app.data.admin.repository

import fr.labodoc.api.AdminApiClient
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.responses.AtcClassificationResponse
import fr.labodoc.api.payloads.responses.AtcClassificationsResponse
import fr.labodoc.app.data.admin.model.AtcClassificationModel
import fr.labodoc.domain.labodoc.atcclassification.AtcClassificationCode

class AtcClassificationRepositoryImpl(
  private val apiClient: AdminApiClient
) : AtcClassificationRepository {
  override suspend fun getAtcClassifications(
  ): ApiResponse<Set<AtcClassificationModel>> =
    apiClient.atcClassification
      .getAtcClassifications()
      .map { atcClassificationsResponse: AtcClassificationsResponse ->
        atcClassificationsResponse.toModel()
      }

  override suspend fun getAtcClassification(
    code: AtcClassificationCode
  ): ApiResponse<AtcClassificationModel> =
    apiClient.atcClassification
      .getAtcClassification(code)
      .map { atcClassificationResponse: AtcClassificationResponse ->
        atcClassificationResponse.toModel()
      }
}

/**
 * Top-level extension:
 * Converts an entire response (with possibly multiple top-level classifications)
 * into a set of domain AtcClassificationModel.
 */
private fun AtcClassificationsResponse.toModel(): Set<AtcClassificationModel> {
  return atcClassifications
    .map { it.toModel(parent = null) }
    .toSet()
}

/**
 * Recursive extension:
 * Constructs one domain AtcClassificationModel from
 * this DTO, setting `parent` to the passed-in model.
 */
private fun AtcClassificationsResponse.AtcClassification.toModel(
  parent: AtcClassificationModel?
): AtcClassificationModel {
  // First, create the model with an empty children set:
  val model = AtcClassificationModel(
    code = code,
    name = label,
    parent = parent,
    children = emptySet()
  )

  // Then, build the actual children, passing in the "parent = model" reference:
  val resolvedChildren = children.map { childDto ->
    childDto.toModel(parent = model)
  }.toSet()

  // Finally, return a copy of model that has the fully resolved children set.
  return model.copy(children = resolvedChildren)
}

private fun AtcClassificationResponse.toModel(): AtcClassificationModel {
  // 1) Collect the chain from bottom to top
  val chainBottomToTop = generateSequence(atcClassification) { current -> current.parent }.toList()

  // 2) Reverse it so that index 0 is the top node,
  //    and the last item is the bottom node
  val chainTopToBottom = chainBottomToTop.asReversed()

  // 3) Convert each DTO item into an empty AtcClassificationModel
  //    (we will fill parent/children relationships below)
  val models = chainTopToBottom.map {
    AtcClassificationModel(
      code = it.code,
      name = it.label,
      parent = null,
      children = emptySet()
    )
  }.toMutableList()

  // 4) Link each parent to its child
  for (i in 0 until (models.size - 1)) {
    val parentModel = models[i]
    val childModel  = models[i + 1]

    // Set child's parent
    val updatedChild = childModel.copy(parent = parentModel)
    models[i + 1] = updatedChild

    // Add child into parent's children
    val updatedParent = parentModel.copy(children = parentModel.children + updatedChild)
    models[i] = updatedParent
  }

  // The first item in "models" is now the topmost classification
  // with fully linked descendants down to the bottom node.
  return models.last()
}
