package fr.labodoc.api.client

import arrow.core.left
import arrow.core.right
import fr.labodoc.api.ApiResponse
import fr.labodoc.api.payloads.responses.AtcClassificationResponse
import fr.labodoc.api.payloads.responses.AtcClassificationsResponse
import fr.labodoc.api.payloads.responses.ErrorResponse
import fr.labodoc.api.resources.AtcClassifications
import fr.labodoc.api.routes.AtcClassificationRoutes
import fr.labodoc.domain.labodoc.atcclassification.AtcClassificationCode
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.resources.*
import io.ktor.http.*

class AtcClassificationRemoteDataSource(
  private val httpClient: HttpClient
) : AtcClassificationRoutes {
  override suspend fun getAtcClassifications(
  ): ApiResponse<AtcClassificationsResponse> {
    val response = httpClient.get(AtcClassifications())

    return if (response.status.isSuccess())
      response.body<AtcClassificationsResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }

  override suspend fun getAtcClassification(
    code: AtcClassificationCode
  ): ApiResponse<AtcClassificationResponse> {
    val response = httpClient.get(AtcClassifications.AtcClassification(code))

    return if (response.status.isSuccess())
      response.body<AtcClassificationResponse>().right()
    else
      response.body<ErrorResponse>().left()
  }
}
