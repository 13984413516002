package fr.labodoc.webapp.pages.healthProfessional.dashboard.components

import fr.labodoc.app.data.healthprofessional.model.HealthProfessionalUserModel
import fr.labodoc.app.data.healthprofessional.model.MedicineSummaryModel
import fr.labodoc.domain.labodoc.atcclassification.AtcClassificationCode
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.hr
import fr.labodoc.webapp.components.labodocSelectCheckbox
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.components.navigoLink
import io.kvision.core.Display
import io.kvision.form.check.radio
import io.kvision.form.text.text
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.state.bindEach
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class MedicineList : SimplePanel(className = "medicine-list"), KoinComponent {
  private val viewModel: MedicineListViewModel by inject()

  private val filterOptionsOpen = ObservableValue(false)
  private val sortOptionsOpen = ObservableValue(false)

  init {
    bind(viewModel.uiState) { uiState ->
      header {
        App.user.getState()?.let { user ->
          if (user is HealthProfessionalUserModel) {
            div(className = "speciality") {
              h2 {
                content = "Univers ${user.medicalSpeciality.name.value}"
              }

              user.medicalSpeciality.partnership?.let { partnership ->
                p {
                  content = "En partenariat avec ${partnership.name.value}"
                }
              }
            }
          }
        }

        div(className = "search-bar") {
          text {
            placeholder = "Chercher par nom ou DCI"
            subscribe(viewModel::updateSearchInput)
          }.bind(uiState.filter.searchInput) { searchInput ->
            value = searchInput
          }
        }

        div(className = "toggles") {
          button("Filtres") {
            onClick { filterOptionsOpen.value = !filterOptionsOpen.value }

            filterOptionsOpen.subscribe {
              if (it) addCssClass("open") else removeCssClass("open")
            }
          }
          button("Trier par") {
            onClick { sortOptionsOpen.value = !sortOptionsOpen.value }

            sortOptionsOpen.subscribe {
              if (it) addCssClass("open") else removeCssClass("open")
            }
          }
        }

        div(className = "filter-options") {
          p {
            content = "Filter par : "
          }

          labodocSelectCheckbox(label = "Code ATC") {
            options = uiState.filter.atcClassificationFilterOptions

            addCssClass("atc")

            subscribe {
              val atcCodeCodes = values?.mapNotNull { AtcClassificationCode(it).getOrNull() }.orEmpty().toSet()

              viewModel.updateAtcClassificationFilter(atcCodeCodes)
            }
          }.bind(uiState.filter.atcClassificationFilter, removeChildren = false) { filter ->
            values = filter.map { it.value }
          }

          filterOptionsOpen.subscribe { filterOptionsOpen ->
            display = if (filterOptionsOpen) Display.FLEX else Display.NONE
          }
        }

        div(className = "sort-options") {
          p {
            content = "Trier par : "
          }

          radio(
            name = "sort-option",
            label = "Défaut"
          ) {
            subscribe { enabled ->
              if (enabled)
                viewModel.updateSort(MedicineListViewModel.Sort.Default)
            }
          }.bind(uiState.sort) { sort ->
            value = sort is MedicineListViewModel.Sort.Default
          }

          radio(
            name = "sort-option",
            label = "Ordre alphabétique"
          ) {
            subscribe { enabled ->
              if (enabled)
                viewModel.updateSort(MedicineListViewModel.Sort.Alphabetical)
            }
          }.bind(uiState.sort) { sort ->
            value = sort is MedicineListViewModel.Sort.Alphabetical
          }

          radio(
            name = "sort-option",
            label = "Code ATC"
          ) {
            subscribe { enabled ->
              if (enabled)
                viewModel.updateSort(MedicineListViewModel.Sort.AtcClassificationCode)
            }
          }.bind(uiState.sort) { sort ->
            value = sort is MedicineListViewModel.Sort.AtcClassificationCode
          }

          sortOptionsOpen.subscribe { sortOptionsOpen ->
            display = if (sortOptionsOpen) Display.FLEX else Display.NONE
          }
        }
      }

      div(className = "container") {
        when (uiState) {
          is MedicineListViewModel.UiState.Loading -> {
            labodocSpinner()
          }

          is MedicineListViewModel.UiState.Error -> {
            p("Une erreur est survenue")
          }

          is MedicineListViewModel.UiState.MedicineList -> {
            if (uiState.medicines.isEmpty()) {
              p("Aucun médicaments", className = "no-medicines")
            } else {
              div(className = "medicine-cards").bindEach(uiState.medicines) { medicine ->
                navigoLink("", Page.HealthProfessionalMedicineSheet(medicine.id)) {
                  add(MedicineCard(medicine))
                }
              }
            }
          }
        }
      }
    }
  }
}

class MedicineCard(
  medicine: MedicineSummaryModel
) : SimplePanel("medicine-card atc-category-${medicine.atcClassification.firstLevel?.code?.value}") {
  init {
    image(medicine.logoUrl.toString(), className = "logo")

    hr()

    p(className = "name") {
      content = medicine.name.value
    }

    p(className = "atc-first-level") {
      content = medicine.atcClassification.firstLevel?.name?.value
    }

    p(className = "laboratory") {
      content = medicine.laboratory.name.value
    }

    div(className = "atc-second-level") {
      p {
        content = "${medicine.atcClassification.secondLevel?.code?.value}"
      }
    }
  }
}
