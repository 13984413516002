package fr.labodoc.api.payloads.serializers

import arrow.core.Either
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.atcclassification.AtcClassificationCode
import fr.labodoc.domain.labodoc.atcclassification.AtcClassificationName
import kotlinx.serialization.KSerializer
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

object AtcClassificationCodeAsStringSerializer : KSerializer<AtcClassificationCode> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("AtcClassificationCodeAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(code: String): Either<Errors.AtcClassification.Code.Invalid, AtcClassificationCode> =
    AtcClassificationCode(code)

  override fun deserialize(decoder: Decoder): AtcClassificationCode =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(code: AtcClassificationCode): String =
    code.value

  override fun serialize(encoder: Encoder, value: AtcClassificationCode) =
    encoder.encodeString(serialize(value))
}

typealias AtcClassificationCodeAsString = @Serializable(AtcClassificationCodeAsStringSerializer::class) AtcClassificationCode

object AtcClassificationLabelAsStringSerializer : KSerializer<AtcClassificationName> {
  override val descriptor: SerialDescriptor =
    PrimitiveSerialDescriptor("AtcClassificationLabelAsStringSerializer", PrimitiveKind.STRING)

  fun deserialize(label: String): Either<Errors.AtcClassification.Name.Invalid, AtcClassificationName> =
    AtcClassificationName(label)

  override fun deserialize(decoder: Decoder): AtcClassificationName =
    deserialize(decoder.decodeString())
      .fold(
        { throw SerializationValidationException(it) },
        { it }
      )

  fun serialize(label: AtcClassificationName): String =
    label.value

  override fun serialize(encoder: Encoder, value: AtcClassificationName) =
    encoder.encodeString(serialize(value))
}

typealias AtcClassificationLabelAsString = @Serializable(AtcClassificationLabelAsStringSerializer::class) AtcClassificationName
