package fr.labodoc.domain.labodoc.atcclassification

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import fr.labodoc.domain.labodoc.Errors
import kotlin.jvm.JvmInline

@JvmInline
value class AtcClassificationCode private constructor(val value: String) {
  companion object {
    val regex: Regex = Regex("^([ABCDGHJLMNPRSV])(\\d{2})?([A-Z]{1,2})?(\\d{2})?\$")

    fun isFormatValid(value: String): Boolean = regex.containsMatchIn(value)

    operator fun invoke(value: String): Either<Errors.AtcClassification.Code.Invalid, AtcClassificationCode> =
      either {
        ensure(isFormatValid(value)) { Errors.AtcClassification.Code.Invalid.Format }

        AtcClassificationCode(value)
      }
  }
}
