package fr.labodoc.webapp

import com.benasher44.uuid.uuidFrom
import fr.labodoc.Cookie
import fr.labodoc.Cookies
import fr.labodoc.app.data.healthprofessional.model.AdminUserModel
import fr.labodoc.app.data.healthprofessional.model.HealthProfessionalUserModel
import fr.labodoc.app.data.healthprofessional.model.UserModel
import fr.labodoc.app.data.healthprofessional.repository.UsersRepository
import fr.labodoc.app.di.adminRepositoryModule
import fr.labodoc.app.di.healthProfessionalRepositoryModule
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.learnedsociety.LearnedSocietyId
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.domain.labodoc.message.MessageId
import fr.labodoc.domain.labodoc.partnership.PartnershipId
import fr.labodoc.webapp.Page.AdminBackOfficeMedicineCreate
import fr.labodoc.webapp.components.LabodocPopup
import fr.labodoc.webapp.di.dataSourceModule
import fr.labodoc.webapp.di.viewModelModule
import fr.labodoc.webapp.layouts.adminLayout
import fr.labodoc.webapp.layouts.healthProfessionalLayout
import fr.labodoc.webapp.pages.aboutUs.aboutUsPage
import fr.labodoc.webapp.pages.aboutUs.fAQPage
import fr.labodoc.webapp.pages.aboutUs.whoAreWePage
import fr.labodoc.webapp.pages.admin.healthDirectory.adminHealthDirectoryPage
import fr.labodoc.webapp.pages.admin.laboratories.adminLaboratoriesPage
import fr.labodoc.webapp.pages.admin.laboratories.adminLaboratoryCreatePage
import fr.labodoc.webapp.pages.admin.laboratories.adminLaboratoryPage
import fr.labodoc.webapp.pages.admin.laboratories.adminLaboratoryUpdatePage
import fr.labodoc.webapp.pages.admin.learnedSocieties.adminLearnedSocietiesPage
import fr.labodoc.webapp.pages.admin.learnedSocieties.adminLearnedSocietyCreatePage
import fr.labodoc.webapp.pages.admin.learnedSocieties.adminLearnedSocietyUpdatePage
import fr.labodoc.webapp.pages.admin.medicalDiplomas.adminMedicalDiplomasPage
import fr.labodoc.webapp.pages.admin.medicalProfessions.adminMedicalProfessionsPage
import fr.labodoc.webapp.pages.admin.medicines.create.adminMedicineCreatePage
import fr.labodoc.webapp.pages.admin.medicines.view.adminMedicinePage
import fr.labodoc.webapp.pages.admin.medicines.update.adminMedicineUpdatePage
import fr.labodoc.webapp.pages.admin.medicines.list.adminMedicinesPages
import fr.labodoc.webapp.pages.admin.message.adminMessageCreatePage
import fr.labodoc.webapp.pages.admin.message.adminMessageSheetPage
import fr.labodoc.webapp.pages.admin.message.adminMessageUpdatePage
import fr.labodoc.webapp.pages.admin.message.adminMessagesPage
import fr.labodoc.webapp.pages.admin.partner.adminPartnersPage
import fr.labodoc.webapp.pages.admin.partnership.adminPartnershipCreatePage
import fr.labodoc.webapp.pages.admin.partnership.adminPartnershipUpdatePage
import fr.labodoc.webapp.pages.admin.partnership.adminPartnershipsPage
import fr.labodoc.webapp.pages.admin.universityHospitals.adminUniversityHospitalsPage
import fr.labodoc.webapp.pages.admin.users.adminUsersAdministrationPage
import fr.labodoc.webapp.pages.contact.contactPage
import fr.labodoc.webapp.pages.healthProfessional.dashboard.healthProfessionalDashboardPage
import fr.labodoc.webapp.pages.healthProfessional.laboratories.healthProfessionalLaboratoriesPage
import fr.labodoc.webapp.pages.healthProfessional.learnedSocieties.healthProfessionalLearnedSocietiesPage
import fr.labodoc.webapp.pages.healthProfessional.medicines.healthProfessionalMedicinePage
import fr.labodoc.webapp.pages.healthProfessional.profile.healthProfessionalMedicalInterestsPage
import fr.labodoc.webapp.pages.healthProfessional.profile.healthProfessionalNotificationsSettingsPage
import fr.labodoc.webapp.pages.healthProfessional.profile.healthProfessionalProfileInformationPage
import fr.labodoc.webapp.pages.home.homePage
import fr.labodoc.webapp.pages.login.loginPage
import fr.labodoc.webapp.pages.notFound.notFoundPage
import fr.labodoc.webapp.pages.register.registerPage
import fr.labodoc.webapp.utils.toMap
import io.github.aakira.napier.DebugAntilog
import io.github.aakira.napier.Napier
import io.kvision.*
import io.kvision.core.TextAlign
import io.kvision.core.style
import io.kvision.html.h2
import io.kvision.html.p
import io.kvision.i18n.DefaultI18nManager
import io.kvision.i18n.I18n
import io.kvision.panel.root
import io.kvision.routing.Routing
import io.kvision.routing.Strategy
import io.kvision.state.MutableState
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.toast.Toast
import io.kvision.utils.Object
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.asCoroutineDispatcher
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.context.startKoin
import org.w3c.dom.INSTANT
import org.w3c.dom.ScrollBehavior
import org.w3c.dom.ScrollToOptions

class App : Application(), KoinComponent {
  companion object : KoinComponent {
    val scope = CoroutineScope(window.asCoroutineDispatcher())

    val routing: Routing = Routing.init("/", useHash = false, strategy = Strategy.ONE)

    val user: MutableState<UserModel?> = ObservableValue(null)
  }

  private val userRepository: UsersRepository by inject()

  private val currentPage: MutableState<Page?> = ObservableValue(null)

  init {
    require("./css/theme.css")

    Napier.base(DebugAntilog())

    startKoin {
      modules(
        dataSourceModule,
        healthProfessionalRepositoryModule,
        adminRepositoryModule,
        viewModelModule
      )
    }

    val developmentWarningPopup = LabodocPopup(
      closeButton = true,
      icon = "fa-solid fa-warning",
      image = null,
      className = null,
      content =  {
        h2("Site en développement") {
          style {
            textAlign = TextAlign.CENTER
          }
        }

        p {
          rich = true
          content = """Ce site est actuellement en cours de développement.<br>
            <br>
            Tout le contenu présent sur ce site est à unique but de démonstration de la solution.
          """.trimIndent()

          style {
            textAlign = TextAlign.CENTER
          }
        }
      }
    )

    if ((Cookie.get(Cookies.DevelopmentWarning) as? String)?.toBooleanStrictOrNull() != true) {
      developmentWarningPopup.show()
      Cookie.set(Cookies.DevelopmentWarning, true, Cookie.Options(expires = 2))
    }
  }

  override fun start() {
    I18n.manager =
      DefaultI18nManager(
        mapOf(
          "fr" to require("./i18n/messages-fr.json")
        )
      )
    I18n.language = "fr"

    routing
      .on(
        Page.Home.URL_PATTERN,
        {
          val params = (it.params as? Object).toMap()
          val registrationSuccessful = (params?.get(Page.Home.REGISTRATION_SUCCESSFUL_QUERY_KEY) as String?)?.toBooleanStrictOrNull()
          val registrationConfirmEmailToken = (params?.get(Page.Home.REGISTRATION_CONFIRM_EMAIL_TOKEN_QUERY_KEY) as String?)
          val applicationSuccessful = (params?.get(Page.Home.APPLICATION_SUCCESSFUL_QUERY_KEY) as String?)?.toBooleanStrictOrNull()
          val applicationConfirmEmailToken = (params?.get(Page.Home.APPLICATION_CONFIRM_EMAIL_TOKEN_QUERY_KEY) as String?)
          val resetPasswordToken = (params?.get(Page.Home.RESET_PASSWORD_TOKEN_QUERY_KEY) as String?)

          val popup = when {
            registrationSuccessful != null -> Page.Home.Popup.RegistrationSuccessful(registrationSuccessful)
            registrationConfirmEmailToken != null -> Page.Home.Popup.RegistrationConfirmEmail(registrationConfirmEmailToken)
            applicationSuccessful != null -> Page.Home.Popup.ApplicationSuccessful(applicationSuccessful)
            applicationConfirmEmailToken != null -> Page.Home.Popup.ApplicationConfirmEmail(applicationConfirmEmailToken)
            resetPasswordToken != null -> Page.Home.Popup.ResetPassword(resetPasswordToken)
            else -> null
          }

          currentPage.setState(Page.Home(popup))
        }
      )
      .on(
        Page.Login.URL_PATTERN,
        {
          val params = (it.params as? Object).toMap()

          when (user.getState()) {
            is AdminUserModel -> routing.navigate("/admin")
            is HealthProfessionalUserModel -> routing.navigate("/health-professional")
            null -> currentPage.setState(Page.Login(params?.get(Page.Login.REDIRECT_TO_QUERY_KEY) as String?))
          }
        }
      )
      .on(
        Page.Logout.URL_PATTERN,
        { currentPage.setState(Page.Logout()) }
      )
      .on(
        Page.Register.URL_PATTERN,
        { currentPage.setState(Page.Register()) }
      )
      .on(
        Page.Contact.URL_PATTERN,
        { currentPage.setState(Page.Contact()) }
      )
      .on(
        Page.FAQ.URL_PATTERN,
        { currentPage.setState(Page.FAQ()) }
      )
      .on(
        Page.About.URL_PATTERN,
        { currentPage.setState(Page.About()) }
      )
      .on(
        Page.WhoAreWe.URL_PATTERN,
        { currentPage.setState(Page.WhoAreWe()) }
      )
      .on(
        Page.HealthProfessionalDashboard.URL_PATTERN,
        { currentPage.setState(Page.HealthProfessionalDashboard()) }
      )
      .on(
        Page.HealthProfessionalMedicineSheet.URL_PATTERN,
        {
          val medicineId = MedicineId(uuidFrom(it.data["id"] as String)) // TODO error handling
          currentPage.setState(Page.HealthProfessionalMedicineSheet(medicineId))
        }
      )
      .on(
        Page.HealthProfessionalLaboratoriesList.URL_PATTERN,
        { currentPage.setState(Page.HealthProfessionalLaboratoriesList()) }
      )
      .on(
        Page.HealthProfessionalLearnedSocietiesList.URL_PATTERN,
        { currentPage.setState(Page.HealthProfessionalLearnedSocietiesList()) }
      )
      .on(
        Page.HealthProfessionalProfileInformation.URL_PATTERN,
        { currentPage.setState(Page.HealthProfessionalProfileInformation()) }
      )
      .on(
        Page.HealthProfessionalProfileMedicalInterests.URL_PATTERN,
        { currentPage.setState(Page.HealthProfessionalProfileMedicalInterests()) }
      )
      .on(
        Page.HealthProfessionalProfileNotificationsSettings.URL_PATTERN,
        { currentPage.setState(Page.HealthProfessionalProfileNotificationsSettings()) }
      )
      .on(
        Page.AdminBackOfficeUsersAdministration.URL_PATTERN,
        { currentPage.setState(Page.AdminBackOfficeUsersAdministration()) }
      )
      .on(
        Page.AdminBackOfficeLaboratoriesList.URL_PATTERN,
        { currentPage.setState(Page.AdminBackOfficeLaboratoriesList()) }
      )
      .on(
        Page.AdminBackOfficeLaboratoryCreate.URL_PATTERN,
        { currentPage.setState(Page.AdminBackOfficeLaboratoryCreate()) }
      )
      .on(
        Page.AdminBackOfficeLaboratorySheet.URL_PATTERN,
        {
          val laboratoryId = LaboratoryId(uuidFrom(it.data["id"] as String)) // TODO error handling
          currentPage.setState(Page.AdminBackOfficeLaboratorySheet(laboratoryId))
        }
      )
      .on(
        Page.AdminBackOfficeLaboratoryUpdate.URL_PATTERN,
        {
          val laboratoryId = LaboratoryId(uuidFrom(it.data["id"] as String)) // TODO error handling
          currentPage.setState(Page.AdminBackOfficeLaboratoryUpdate(laboratoryId))
        }
      )
      .on(
        Page.AdminBackOfficeMedicinesList.URL_PATTERN,
        { currentPage.setState(Page.AdminBackOfficeMedicinesList()) }
      )
      .on(
        Page.AdminBackOfficeMedicineCreate.URL_PATTERN,
        { currentPage.setState(Page.AdminBackOfficeMedicineCreate()) }
      )
      .on(
        Page.AdminBackOfficeLaboratoryMedicineCreate.URL_PATTERN,
        {
          val laboratoryId = LaboratoryId(uuidFrom(it.data["id"] as String)) // TODO error handling
          currentPage.setState(Page.AdminBackOfficeLaboratoryMedicineCreate(laboratoryId))
        }
      )
      .on(
        Page.AdminBackOfficeMedicineSheet.URL_PATTERN,
        {
          val medicineId = MedicineId(uuidFrom(it.data["id"] as String)) // TODO error handling
          currentPage.setState(Page.AdminBackOfficeMedicineSheet(medicineId))
        }
      )
      .on(
        Page.AdminBackOfficeMedicineUpdate.URL_PATTERN,
        {
          val medicineId = MedicineId(uuidFrom(it.data["id"] as String)) // TODO error handling
          currentPage.setState(Page.AdminBackOfficeMedicineUpdate(medicineId))
        }
      )
      .on(
        Page.AdminBackOfficeMedicalProfessionList.URL_PATTERN,
        { currentPage.setState(Page.AdminBackOfficeMedicalProfessionList()) }
      )
      .on(
        Page.AdminBackOfficeHealthDirectory.URL_PATTERN,
        { currentPage.setState(Page.AdminBackOfficeHealthDirectory()) }
      )
      .on(
        Page.AdminBackOfficeLearnedSocietiesList.URL_PATTERN,
        { currentPage.setState(Page.AdminBackOfficeLearnedSocietiesList()) }
      )
      .on(
        Page.AdminBackOfficeLearnedSocietyCreate.URL_PATTERN,
        { currentPage.setState(Page.AdminBackOfficeLearnedSocietyCreate()) }
      )
      .on(
        Page.AdminBackOfficeLearnedSocietyUpdate.URL_PATTERN,
        {
          val learnedSocietyId = LearnedSocietyId(uuidFrom(it.data["id"] as String))
          currentPage.setState(Page.AdminBackOfficeLearnedSocietyUpdate(learnedSocietyId))
        }
      )
      .on(
        Page.AdminBackOfficeMessagesList.URL_PATTERN,
        { currentPage.setState(Page.AdminBackOfficeMessagesList()) }
      )
      .on(
        Page.AdminBackOfficeMessageCreate.URL_PATTERN,
        { currentPage.setState(Page.AdminBackOfficeMessageCreate()) }
      )
      .on(
        Page.AdminBackOfficeMessageSheet.URL_PATTERN,
        {
          val messageId = MessageId(uuidFrom(it.data["id"] as String)) // TODO error handling
          currentPage.setState(Page.AdminBackOfficeMessageSheet(messageId))
        }
      )
      .on(
        Page.AdminBackOfficeMessageUpdate.URL_PATTERN,
        {
          val messageId = MessageId(uuidFrom(it.data["id"] as String)) // TODO error handling
          currentPage.setState(Page.AdminBackOfficeMessageUpdate(messageId))
        }
      )
      .on(
        Page.AdminBackOfficeUniversityHospitalsList.URL_PATTERN,
        { currentPage.setState(Page.AdminBackOfficeUniversityHospitalsList()) }
      )
      .on(
        Page.AdminBackOfficePartnerList.URL_PATTERN,
        { currentPage.setState(Page.AdminBackOfficePartnerList()) }
      )
      .on(
        Page.AdminBackOfficePartnershipList.URL_PATTERN,
        { currentPage.setState(Page.AdminBackOfficePartnershipList()) }
      )
      .on(
        Page.AdminBackOfficePartnershipCreate.URL_PATTERN,
        { currentPage.setState(Page.AdminBackOfficePartnershipCreate()) }
      )
      .on(
        Page.AdminBackOfficePartnershipUpdate.URL_PATTERN,
        {
          val partnershipId = PartnershipId(uuidFrom(it.data["id"] as String)) // TODO error handling
          currentPage.setState(Page.AdminBackOfficePartnershipUpdate(partnershipId))
        }
      )
      .on(
        Page.AdminBackOfficeMedicalDiplomasList.URL_PATTERN,
        { currentPage.setState(Page.AdminBackOfficeMedicalDiplomasList()) }
      )
      .on( // Redirection
        "/admin",
        { routing.navigate(Page.AdminBackOfficeLaboratoriesList()) }
      )
      .on( // Redirection
        "/health-professional",
        { routing.navigate(Page.HealthProfessionalDashboard()) }
      )
      .notFound({ currentPage.setState(null) })
      .resolve()

    root("labodoc") {}.bind(currentPage) {
      scope.launch {
        val authenticationToken = Cookie.get(Cookies.AuthToken)

        val user = if (authenticationToken != null)
          user.getState() ?: userRepository.getSelf().fold({ null }, { it })?.also { App.user.setState(it) }
        else
          null

        when (user) {
          is AdminUserModel -> {
            if (it is HealthProfessionalPage && it !is AdminPage) {
              Toast.danger("Vous ne pouvez pas accéder à cette page")
              window.history.back()
            }
          }

          is HealthProfessionalUserModel -> {
            if (it is AdminPage && it !is HealthProfessionalPage) {
              Toast.danger("Vous ne pouvez pas accéder à cette page")
              window.history.back()
            }
          }

          null -> {
            if (it is AuthenticatedPage)
              routing.navigate(Page.Login(it.url))
          }
        }

        when (it) {
          is Page.Home -> homePage(it.popup)
          is Page.Login -> loginPage(it.redirectTo)
          is Page.Logout -> {
            scope.launch {
              userRepository.logout()
              Cookie.remove(Cookies.AuthToken)
              App.user.setState(null)
              routing.navigate(Page.Home())
            }
          }

          is Page.Register -> registerPage()
          is Page.Contact -> contactPage()
          is Page.About -> aboutUsPage()
          is Page.WhoAreWe -> whoAreWePage()
          is Page.FAQ -> fAQPage()
          is Page.HealthProfessionalDashboard -> healthProfessionalLayout { healthProfessionalDashboardPage() }
          is Page.HealthProfessionalMedicineSheet -> healthProfessionalLayout { healthProfessionalMedicinePage(it.medicineId) }
          is Page.HealthProfessionalLaboratoriesList -> healthProfessionalLayout { healthProfessionalLaboratoriesPage() }
          is Page.HealthProfessionalLearnedSocietiesList -> healthProfessionalLayout { healthProfessionalLearnedSocietiesPage() }
          is Page.HealthProfessionalProfileInformation -> healthProfessionalLayout { healthProfessionalProfileInformationPage() }
          is Page.HealthProfessionalProfileMedicalInterests -> healthProfessionalLayout { healthProfessionalMedicalInterestsPage() }
          is Page.HealthProfessionalProfileNotificationsSettings -> healthProfessionalLayout { healthProfessionalNotificationsSettingsPage() }
          is Page.AdminBackOfficeUsersAdministration -> adminLayout { adminUsersAdministrationPage() }
          is Page.AdminBackOfficeLaboratoriesList -> adminLayout { adminLaboratoriesPage() }
          is Page.AdminBackOfficeLaboratorySheet -> adminLayout { adminLaboratoryPage(it.laboratoryId) }
          is Page.AdminBackOfficeLaboratoryCreate -> adminLayout { adminLaboratoryCreatePage() }
          is Page.AdminBackOfficeLaboratoryUpdate -> adminLayout { adminLaboratoryUpdatePage(it.laboratoryId) }
          is Page.AdminBackOfficeMedicinesList -> adminLayout { adminMedicinesPages() }
          is AdminBackOfficeMedicineCreate -> adminLayout { adminMedicineCreatePage() }
          is Page.AdminBackOfficeLaboratoryMedicineCreate -> adminLayout { adminMedicineCreatePage(it.laboratoryId) }
          is Page.AdminBackOfficeMedicalProfessionList -> adminLayout { adminMedicalProfessionsPage() }
          is Page.AdminBackOfficeMedicineSheet -> adminLayout { adminMedicinePage(it.medicineId) }
          is Page.AdminBackOfficeMedicineUpdate -> adminLayout { adminMedicineUpdatePage(it.medicineId) }
          is Page.AdminBackOfficeHealthDirectory -> adminLayout { adminHealthDirectoryPage() }
          is Page.AdminBackOfficeLearnedSocietiesList -> adminLayout { adminLearnedSocietiesPage() }
          is Page.AdminBackOfficeLearnedSocietyCreate -> adminLayout { adminLearnedSocietyCreatePage() }
          is Page.AdminBackOfficeLearnedSocietyUpdate -> adminLayout { adminLearnedSocietyUpdatePage(it.learnedSocietyId) }
          is Page.AdminBackOfficeMessagesList -> adminLayout { adminMessagesPage() }
          is Page.AdminBackOfficeMessageSheet -> adminLayout { adminMessageSheetPage(it.messageId) }
          is Page.AdminBackOfficeMessageCreate -> adminLayout { adminMessageCreatePage() }
          is Page.AdminBackOfficeMessageUpdate -> adminLayout { adminMessageUpdatePage(it.messageId) }
          is Page.AdminBackOfficeUniversityHospitalsList -> adminLayout { adminUniversityHospitalsPage() }
          is Page.AdminBackOfficePartnerList -> adminLayout { adminPartnersPage() }
          is Page.AdminBackOfficePartnershipList -> adminLayout { adminPartnershipsPage() }
          is Page.AdminBackOfficePartnershipCreate -> adminLayout { adminPartnershipCreatePage() }
          is Page.AdminBackOfficePartnershipUpdate -> adminLayout { adminPartnershipUpdatePage(it.partnershipId) }
          is Page.AdminBackOfficeMedicalDiplomasList -> adminLayout { adminMedicalDiplomasPage() }
          else -> notFoundPage()
        }

        window.scrollTo(
          options = ScrollToOptions(
            left = 0.0,
            top = 0.0,
            behavior = ScrollBehavior.INSTANT
          )
        )
      }
    }
  }
}

fun main() {
  startApplication(
    ::App,
    module.hot,
    DatetimeModule,
    CoreModule
  )
}
