package fr.labodoc.api.payloads.requests

import fr.labodoc.api.payloads.serializers.*
import fr.labodoc.domain.labodoc.common.NotificationFrequency
import kotlinx.serialization.Serializable

@Serializable
data class NoMedicalCardRegistration(
  val rppsNumber: RPPSNumberAsString?,
  val firstName: FirstNameAsString,
  val lastName: LastNameAsString,
  val phoneNumber: PhoneNumberAsString,
  val medicalSpeciality: MedicalSpecialityIdAsString,
  val professionalCategoryCode: ProfessionalCategoryCodeAsString,
  val professionalStatusCode: ProfessionalStatusCodeAsString,
  val facilityName: String,
  val facilityUnit: String,
  val facilityCity: String,
  val facilityDepartmentCode: DepartmentCodeAsString,
  val notificationFrequency: NotificationFrequency?,
  val emailAddress: EmailAddressAsString,
  val password: PasswordAsString,
  val comment: String?,
  val partnerCode: PartnerCodeAsString?
)
