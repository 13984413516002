package fr.labodoc.webapp.pages.admin.medicines.list

import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.hr
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.components.navigoLink
import fr.labodoc.webapp.navigate
import fr.labodoc.webapp.pages.admin.medicines.MedicineDeleteConfirmationModal
import io.kvision.core.Container
import io.kvision.core.onClick
import io.kvision.html.*
import io.kvision.panel.SimplePanel
import io.kvision.state.ObservableValue
import io.kvision.state.bind
import io.kvision.state.bindEach
import io.kvision.toast.Toast
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject

class AdminMedicinesPage : SimplePanel(), KoinComponent {
  private val viewModel: AdminMedicinesPageViewModel by inject()

  init {
    id = "page-admin-medicines"
    require("./css/pages/admin/medicines/medicines.css")

    viewModel.errorMessage.subscribe { errorMessage ->
      errorMessage?.let { Toast.danger(it) }
    }

    viewModel.successMessage.subscribe { successMessage ->
      successMessage?.let { Toast.success(it) }
    }

    div(className = "page-width") {
      header {
        h1("Liste des médicaments")

        navigoLink("", Page.AdminBackOfficeMedicineCreate()) {
          labodocButton("Ajouter un médicament", "fa-solid fa-plus")
        }
      }

      div(className = "container").bind(viewModel.uiState) { uiState ->
        when (uiState) {
          AdminMedicinesPageViewModel.UiState.Loading -> {
            labodocSpinner()
          }

          AdminMedicinesPageViewModel.UiState.Error -> {
            p("Impossible de récupérer la liste des médicaments")
          }

          is AdminMedicinesPageViewModel.UiState.Medicines -> {
            div(className = "medicines").bindEach(uiState.medicines) { medicine ->
              val deleteConfirmationModal = MedicineDeleteConfirmationModal(
                name = medicine.name,
                onDeleteConfirmed = { viewModel.deleteMedicine(medicine) },
                processing = medicine.processing
              )

              div(className = "medicine") {
                header {
                  image(medicine.logoUrl.toString()) {
                    onClick { App.routing.navigate(Page.AdminBackOfficeMedicineSheet(medicine.id)) }
                  }

                  navigoLink(
                    medicine.name.value,
                    Page.AdminBackOfficeMedicineSheet(medicine.id),
                    className = "name"
                  )
                }

                hr()

                div(className = "actions").bind(medicine.processing) { processing ->
                  if (processing) {
                    icon("fa fa-spinner fa-spin")
                  } else {
                    navigoLink(
                      "Mettre à jour",
                      Page.AdminBackOfficeMedicineUpdate(medicine.id),
                      icon = "fa-solid fa-pencil",
                      className = "action edit"
                    )

                    labodocButton("Supprimer", icon = "fa-solid fa-trash", className = "action delete") {
                      onClick {
                        deleteConfirmationModal.show()
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

fun Container.adminMedicinesPages(): AdminMedicinesPage {
  val adminMedicinesPage = AdminMedicinesPage()

  this.add(adminMedicinesPage)
  return adminMedicinesPage
}
