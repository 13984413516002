package fr.labodoc.webapp.utils

import io.kvision.state.ObservableListWrapper

fun <T> ObservableListWrapper<T>.set(predicate: (T) -> Boolean, element: T) {
  mutableList
    .indexOfFirst(predicate)
    .takeIf { index -> index != -1 }
    ?.let { index -> set(index, element) }
}

fun <T> ObservableListWrapper<T>.remove(predicate: (T) -> Boolean) {
  mutableList
    .find(predicate)
    ?.let { element -> remove(element) }
}
