package fr.labodoc.api.payloads.requests

import fr.labodoc.api.payloads.serializers.*
import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class UpdateMarketingDocumentRequest(
  val name: MarketingDocumentNameAsString,
  val segmentation: Segmentation,
  val expiresAt: Instant?
) {
  @Serializable
  data class Segmentation(
    val medicalProfessions: Set<MedicalProfessionIdAsString>?,
    val medicalSpecialities: Set<MedicalSpecialityIdAsString>?,
    val medicalInterests: Set<MedicalInterestIdAsString>?,
    val professionalStatuses: Set<ProfessionalStatusCodeAsString>?,
    val medicalCardTypes: Set<MedicalCardTypeCodeAsString>?,
  )
}
