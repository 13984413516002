package fr.labodoc.webapp.pages.admin.medicines

import fr.labodoc.domain.labodoc.medicine.DosageName
import fr.labodoc.require
import fr.labodoc.webapp.components.LabodocPopup
import fr.labodoc.webapp.components.labodocButton
import io.kvision.html.div
import io.kvision.html.p
import io.kvision.state.ObservableState
import io.kvision.state.bind

class DosageDeleteConfirmationModal(
  name: DosageName,
  onDeleteConfirmed: () -> Unit,
  processing: ObservableState<Boolean>
) {
  private val modal = LabodocPopup(
    closeButton = true,
    icon = "fa-solid fa-warning",
    image = null,
    className = "admin-medicine-delete-confirmation-modal",
    content = { modal ->
      p(className = "title") {
        content = "Êtes-vous sûr de vouloir supprimer le dosage \"${name.value}\" ?"
      }

      div(className = "choices").bind(processing) { processing ->
        if (processing) {
          labodocButton("Traitement", icon = "fa fa-spinner fa-spin", className = "delete")
        } else {
          labodocButton("Supprimer", icon = "fa-solid fa-trash", className = "delete") {
            onClick {
              onDeleteConfirmed()
            }
          }

          labodocButton("Annuler", icon = "fa-solid fa-cancel", className = "cancel") {
            onClick {
              modal.hide()
            }
          }
        }
      }
    },
    beforeClose = null
  )

  init {
    require("./css/pages/admin/medicines/admin-medicine-delete-confirmation-modal.css")
  }

  fun show() {
    modal.show()
  }

  fun hide() {
    modal.hide()
  }
}
