package fr.labodoc.api

import fr.labodoc.api.client.*
import io.ktor.client.*

class AdminApiClient(httpClient: HttpClient) {
  val applications = AdminApplicationsRemoteDataSource(httpClient)
  val atcClassification = AtcClassificationRemoteDataSource(httpClient)
  val contact = ContactRemoteDataSource(httpClient)
  val departments = DepartmentsRemoteDataSource(httpClient)
  val healthDirectory = HealthDirectoryRemoteDataSource(httpClient)
  val laboratories = AdminLaboratoriesRemoteDataSource(httpClient)
  val learnedSocieties = AdminLearnedSocietiesRemoteDataSource(httpClient)
  val medicalDiplomas = AdminMedicalDiplomasRemoteDataSource(httpClient)
  val medicalProfessions = AdminMedicalProfessionsRemoteDataSource(httpClient)
  val medicines = AdminMedicinesRemoteDataSource(httpClient)
  val messages = AdminMessagesRemoteDataSource(httpClient)
  val partners = AdminPartnersRemoteDataSource(httpClient)
  val partnerships = AdminPartnershipsRemoteDataSource(httpClient)
  val segmentationTemplates = SegmentationTemplateRemoteDataSource(httpClient)
  val universityHospitals = AdminUniversityHospitalsRemoteDataSource(httpClient)
  val users = AdminUsersRemoteDataSource(httpClient)
}
