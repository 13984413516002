package fr.labodoc.domain.labodoc.medicine

import arrow.core.Either
import arrow.core.raise.either
import arrow.core.raise.ensure
import fr.labodoc.domain.labodoc.Errors
import kotlin.jvm.JvmInline

/**
 * [Definition](https://sante.gouv.fr/soins-et-maladies/medicaments/glossaire/article/code-cis)
 */
@JvmInline
value class CisCode private constructor(val value: String) {
  companion object {
    val regex: Regex = Regex("^\\d{8}\$")

    fun isFormatValid(value: String): Boolean = regex.matches(value)

    operator fun invoke(value: String): Either<Errors.CisCode.Invalid, CisCode> =
      either {
        ensure(isFormatValid(value)) { Errors.CisCode.Invalid.Format }

        CisCode(value)
      }
  }
}
