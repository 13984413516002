package fr.labodoc.api.payloads.responses

import fr.labodoc.api.payloads.serializers.*
import kotlinx.serialization.Serializable

@Serializable
data class AdminMedicinesSummaryResponse(
  val medicines: Set<Medicine>
) {
  @Serializable
  data class Medicine(
    val id: MedicineIdAsString,
    val laboratory: Laboratory,
    val name: MedicineNameAsString,
    val atcClassificationCode: AtcClassificationCodeAsString,
    val logoUrl: UrlAsString,
    val activated: Boolean
  ) {
    @Serializable
    data class Laboratory(
      val id: LaboratoryIdAsString,
      val name: LaboratoryNameAsString
    )
  }
}
