package fr.labodoc.webapp.pages.admin.medicines.create

import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.require
import fr.labodoc.webapp.App
import fr.labodoc.webapp.Page
import fr.labodoc.webapp.components.labodocButton
import fr.labodoc.webapp.components.labodocSpinner
import fr.labodoc.webapp.navigate
import fr.labodoc.webapp.pages.admin.medicines.adminMedicineForm
import io.kvision.core.Container
import io.kvision.html.div
import io.kvision.html.h1
import io.kvision.panel.SimplePanel
import io.kvision.state.bind
import io.kvision.toast.Toast
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import org.koin.core.parameter.parametersOf

class AdminMedicineCreatePage(
  laboratoryId: LaboratoryId?
) : SimplePanel(), KoinComponent {
  private val viewModel: AdminMedicineCreatePageViewModel by inject { parametersOf(laboratoryId) }

  init {
    id = "page-admin-medicine-create"
    require("./css/pages/admin/medicines/create.css")

    viewModel.errorMessage.subscribe { errorMessage ->
      errorMessage?.let { Toast.danger(it) }
    }

    viewModel.successMessage.subscribe { successMessage ->
      successMessage?.let { Toast.success(it) }
    }

    div(className = "page-width").bind(viewModel.uiState) { uiState ->
      h1("Créer un médicament")

      when (uiState) {
        is AdminMedicineCreatePageViewModel.UiState.Loading -> {
          labodocSpinner()
        }

        is AdminMedicineCreatePageViewModel.UiState.Error -> {
          Toast.danger("Une erreur est survenue")
        }

        is AdminMedicineCreatePageViewModel.UiState.Form -> {
          val medicineForm = adminMedicineForm(
            uiState.laboratories.map { it.id to it.name }.toSet(),
            uiState.medicalProfessions,
            null
          ).apply {
            setData(uiState.formData)
          }

          labodocButton(className = "save") {
            onClick {
              viewModel.createMedicine(medicineForm)
            }
          }.bind(uiState.processing) { processing ->
            if (processing) {
              disabled = true
              text = "Traitement"
              icon = "fa fa-spinner fa-spin"
            } else {
              disabled = false
              text = "Sauvegarder"
              icon = null
            }
          }
        }

        is AdminMedicineCreatePageViewModel.UiState.MedicineCreated -> {
          App.routing.navigate(Page.AdminBackOfficeMedicineSheet(uiState.id))
        }
      }
    }
  }
}

fun Container.adminMedicineCreatePage(
  laboratoryId: LaboratoryId? = null
): AdminMedicineCreatePage {
  val adminMedicineCreatePage = AdminMedicineCreatePage(
    laboratoryId = laboratoryId
  )

  this.add(adminMedicineCreatePage)
  return adminMedicineCreatePage
}
