package fr.labodoc.webapp.pages.admin.medicines.view

import arrow.core.flatMap
import arrow.core.raise.either
import arrow.fx.coroutines.parZip
import fr.labodoc.Error
import fr.labodoc.app.data.admin.repository.*
import fr.labodoc.domain.labodoc.Errors
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.webapp.App
import fr.labodoc.webapp.pages.admin.medicines.DosageForm
import fr.labodoc.webapp.pages.admin.medicines.MarketingDocumentForm
import fr.labodoc.webapp.utils.set
import io.kvision.state.ObservableListWrapper
import io.kvision.state.ObservableValue
import kotlinx.coroutines.launch

class AdminMedicinePageViewModelImpl(
  private val medicineId: MedicineId,
  private val medicinesRepository: MedicinesRepository,
  private val laboratoryRepository: LaboratoriesRepository,
  private val medicalProfessionsRepository: MedicalProfessionsRepository,
  private val professionalStatusesRepository: ProfessionalStatusesRepository,
  private val medicalCardTypesRepository: MedicalCardTypesRepository
) : AdminMedicinePageViewModel() {
  private val medicineProcessing: ObservableValue<Boolean> = ObservableValue(false)
  private val medicineDeleteModalOpen: ObservableValue<Boolean> = ObservableValue(false)

  private val marketingDocuments: ObservableListWrapper<MarketingDocument> = ObservableListWrapper()
  private val marketingDocumentsProcessing: ObservableValue<Boolean> = ObservableValue(false)
  private val marketingDocumentsCreateModalOpen: ObservableValue<Boolean> = ObservableValue(false)

  private val dosages: ObservableListWrapper<Dosage> = ObservableListWrapper()
  private val dosagesProcessing: ObservableValue<Boolean> = ObservableValue(false)
  private val dosagesCreateModalOpen: ObservableValue<Boolean> = ObservableValue(false)

  override val uiState: ObservableValue<UiState> by lazy {
    refresh()
    ObservableValue(UiState.Loading)
  }
  override val errorMessage: ObservableValue<String?> = ObservableValue(null)
  override val successMessage: ObservableValue<String?> = ObservableValue(null)

  override fun refresh() {
    App.scope.launch {
      uiState.value = UiState.Loading
      marketingDocuments.clear()
      dosages.clear()

      uiState.value = either {
        parZip(
          {
            val medicine = medicinesRepository.getMedicine(medicineId).bind()
            val laboratory = laboratoryRepository.getLaboratory(medicine.laboratory).bind()

            val dosages = medicine.dosages
              .map { dosage ->
                Dosage(
                  cisCode = dosage.cisCode,
                  name = dosage.name,
                  processing = ObservableValue(false),
                  deleteModalOpen = ObservableValue(false)
                )
              }
              .toSet()
            this@AdminMedicinePageViewModelImpl.dosages.addAll(dosages)

            Triple(
              Medicine(
                id = medicine.id,
                name = medicine.name,
                atcClassification = medicine.atcClassification,
                logoUrl = medicine.logoUrl,
                segmentation = medicine.segmentation,
                processing = medicineProcessing,
                deleteModalOpen = medicineDeleteModalOpen
              ),
              Laboratory(
                id = laboratory.id,
                name = laboratory.name,
                pharmacovigilance = laboratory.pharmacovigilance
              ),
              Dosages(
                dosages = this@AdminMedicinePageViewModelImpl.dosages,
                processing = dosagesProcessing,
                createModalOpen = dosagesCreateModalOpen
              )
            )
          },
          {
            val marketingDocuments = medicinesRepository.getMarketingDocuments(medicineId)
              .bind()
              .map { marketingDocument ->
                MarketingDocument(
                  id = marketingDocument.id,
                  name = marketingDocument.name,
                  segmentation = marketingDocument.segmentation,
                  documentUrl = marketingDocument.documentUrl,
                  expireAt = marketingDocument.expiresAt,
                  processing = ObservableValue(false),
                  editModalOpen = ObservableValue(false),
                  deleteModalOpen = ObservableValue(false)
                )
              }
            this@AdminMedicinePageViewModelImpl.marketingDocuments.addAll(marketingDocuments)

            MarketingDocuments(
              document = this@AdminMedicinePageViewModelImpl.marketingDocuments,
              processing = marketingDocumentsProcessing,
              createModalOpen = marketingDocumentsCreateModalOpen
            )
          },
          { medicalProfessionsRepository.getMedicalProfessions().bind() },
          { professionalStatusesRepository.getProfessionalStatuses().bind() },
          { medicalCardTypesRepository.getMedicalCardTypes().bind() }
        ) { (medicine, laboratory, dosages), marketingDocuments, medicalProfessions, professionalStatuses, medicalCardTypes ->
          UiState.Medicine(
            medicine,
            laboratory,
            marketingDocuments,
            dosages,
            medicalProfessions,
            professionalStatuses,
            medicalCardTypes,
          )
        }
      }.mapLeft {
        UiState.Error
      }.fold({ it }, { it })
    }
  }

  override fun addMarketingDocument(form: MarketingDocumentForm) {
    App.scope.launch {
      marketingDocumentsProcessing.value = true

      form.getValidatedCreateData()
        .onLeft {
          errorMessage.value = "Le formulaire n'est pas valide"
        }
        .onRight { validatedData ->
          medicinesRepository
            .addMarketingDocument(
              medicine = medicineId,
              name = validatedData.name,
              segmentation = validatedData.segmentation,
              document = validatedData.file,
              expiresAt = validatedData.expiresAt
            )
            .onLeft {
              errorMessage.value = "Une erreur est survenue, vérifiez les données et réessayez"
            }
            .onRight {
              successMessage.value = "\"${validatedData.name.value}\" correctement créé"
              marketingDocumentsCreateModalOpen.value = false
            }
            .flatMap {
              medicinesRepository
                .getMarketingDocument(medicineId, it)
            }
            .onRight { marketingDocument ->
              marketingDocuments.add(
                MarketingDocument(
                  id = marketingDocument.id,
                  name = marketingDocument.name,
                  segmentation = marketingDocument.segmentation,
                  documentUrl = marketingDocument.documentUrl,
                  expireAt = marketingDocument.expiresAt,
                  processing = ObservableValue(false),
                  editModalOpen = ObservableValue(false),
                  deleteModalOpen = ObservableValue(false)
                )
              )
            }
        }

      marketingDocumentsProcessing.value = false
    }
  }

  override fun updateMarketingDocument(marketingDocument: MarketingDocument, form: MarketingDocumentForm) {
    App.scope.launch {
      marketingDocument.processing.value = false

      form.getValidatedUpdateData()
        .onLeft {
          errorMessage.value = "Le formulaire n'est pas valide"
        }
        .flatMap { validatedData ->
          medicinesRepository
            .updateMarketingDocument(
              medicine = medicineId,
              id = marketingDocument.id,
              name = validatedData.name,
              segmentation = validatedData.segmentation,
              document = validatedData.file,
              expiresAt = validatedData.expiresAt
            )
            .onLeft {
              errorMessage.value = "Une erreur est survenue, vérifiez les données et réessayez"
            }
            .onRight {
              successMessage.value = "\"${marketingDocument.name.value}\" correctement mis à jour"
              marketingDocument.editModalOpen.value = false
            }
            .flatMap {
              medicinesRepository
                .getMarketingDocument(medicineId, marketingDocument.id)
            }
            .map { marketingDocument ->
              marketingDocuments.set(
                { it.id == marketingDocument.id }, MarketingDocument(
                  id = marketingDocument.id,
                  name = marketingDocument.name,
                  segmentation = marketingDocument.segmentation,
                  documentUrl = marketingDocument.documentUrl,
                  expireAt = marketingDocument.expiresAt,
                  processing = ObservableValue(false),
                  editModalOpen = ObservableValue(false),
                  deleteModalOpen = ObservableValue(false)
                )
              )
            }
        }

      marketingDocument.processing.value = false
    }
  }

  override fun deleteMarketingDocument(marketingDocument: MarketingDocument) {
    App.scope.launch {
      marketingDocument.processing.value = true

      medicinesRepository
        .deleteMarketingDocument(medicineId, marketingDocument.id)
        .onLeft {
          errorMessage.value =
            "Une erreur est survenue lors de la supression du document marketing \"${marketingDocument.name.value}\""
        }
        .onRight {
          successMessage.value = "\"${marketingDocument.name.value}\" correctement supprimé"
          marketingDocument.deleteModalOpen.value = false
          marketingDocuments.remove(marketingDocument)
        }

      marketingDocument.processing.value = false
    }
  }

  override fun addDosage(dosageForm: DosageForm) {
    App.scope.launch {
      dosagesProcessing.value = true

      dosageForm.getValidatedData()
        .onLeft {
          errorMessage.value = "Le formulaire n'est pas valide"
        }
        .onRight { validatedData ->
          medicinesRepository
            .addDosage(
              medicine = medicineId,
              cisCode = validatedData.cisCode,
              name = validatedData.name
            )
            .onLeft { error ->
              errorMessage.value = when (error.code) {
                Errors.CisCode.AlreadyUsed().code -> "Ce code CIS est déjà utilisé"
                else -> "Une erreur est survenue, vérifiez les données et réessayez"
              }
            }
            .onRight {
              successMessage.value = "\"${validatedData.name.value}\" correctement créé"
              dosagesCreateModalOpen.value = false
            }
            .onRight {
              dosages.add(
                Dosage(
                  cisCode = validatedData.cisCode,
                  name = validatedData.name,
                  processing = ObservableValue(false),
                  deleteModalOpen = ObservableValue(false)
                )
              )
            }
        }

      dosagesProcessing.value = false
    }
  }

  override fun deleteDosage(dosage: Dosage) {
    App.scope.launch {
      dosage.processing.value = true

      medicinesRepository
        .deleteDosage(medicineId, dosage.cisCode)
        .onLeft {
          errorMessage.value =
            "Une erreur est survenue lors de la supression du dosage \"${dosage.name.value}\""
        }
        .onRight {
          successMessage.value = "\"${dosage.name.value}\" correctement supprimé"
          dosage.deleteModalOpen.value = false
          dosages.remove(dosage)
        }

      dosage.processing.value = false
    }
  }

  override fun deleteMedicine() {
    App.scope.launch {
      medicineProcessing.value = true

      medicinesRepository.deleteMedicine(medicineId)
        .onLeft {
          errorMessage.value = "Une erreur est survenue"
        }
        .onRight {
          successMessage.value = "Médicament correctement supprimé"
          medicineDeleteModalOpen.value = false
          uiState.value = UiState.MedicineDeleted
        }

      medicineProcessing.value = false
    }
  }
}
