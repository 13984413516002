package fr.labodoc.app.data.admin.model

import fr.labodoc.domain.labodoc.common.Segmentation
import fr.labodoc.domain.labodoc.marketingdocument.MarketingDocumentId
import fr.labodoc.domain.labodoc.marketingdocument.MarketingDocumentName
import io.ktor.http.*
import kotlinx.datetime.Instant

data class MarketingDocumentModel(
  val id: MarketingDocumentId,
  val name: MarketingDocumentName,
  val segmentation: Segmentation,
  val documentUrl: Url,
  val expiresAt: Instant?
)
