package fr.labodoc.webapp.pages.healthProfessional.dashboard.components

import arrow.core.raise.either
import fr.labodoc.app.data.healthprofessional.model.MedicineSummaryModel
import fr.labodoc.app.data.healthprofessional.repository.MedicinesRepository
import fr.labodoc.domain.labodoc.atcclassification.AtcClassificationCode
import fr.labodoc.webapp.App
import fr.labodoc.webapp.pages.healthProfessional.dashboard.components.MedicineListViewModel.Sort
import fr.labodoc.webapp.utils.containsNormalized
import io.kvision.state.ObservableListWrapper
import io.kvision.state.ObservableValue
import kotlinx.coroutines.launch

private val medicineListSearchInputFilter: ObservableValue<String?> = ObservableValue(null)
private val medicineListAtcClassificationsFilter: ObservableListWrapper<AtcClassificationCode> = ObservableListWrapper()

private val medicineListSort: ObservableValue<Sort> = ObservableValue(Sort.Default)

class MedicineListViewModelImpl(
  private val medicineRepository: MedicinesRepository,
) : MedicineListViewModel() {
  private val allMedicines: ObservableListWrapper<MedicineSummaryModel> = ObservableListWrapper()
  private val filteredMedicines: ObservableListWrapper<MedicineSummaryModel> = ObservableListWrapper()
  private val sortedMedicines: ObservableListWrapper<MedicineSummaryModel> = ObservableListWrapper()

  override val uiState: ObservableValue<UiState> by lazy {
    refresh()
    ObservableValue(UiState.Loading(Filter(medicineListSearchInputFilter, mapOf(), medicineListAtcClassificationsFilter), medicineListSort))
  }

  init {
    allMedicines.subscribe { medicines ->
      filteredMedicines.clear()
      filteredMedicines.addAll(filterMedicines(medicines, medicineListSearchInputFilter.value, medicineListAtcClassificationsFilter.toList()))
    }

    filteredMedicines.subscribe { medicines ->
      sortedMedicines.clear()
      sortedMedicines.addAll(sortMedicines(medicines, medicineListSort.value))
    }
  }

  override fun refresh() {
    App.scope.launch {
      uiState.value = UiState.Loading(Filter(medicineListSearchInputFilter, mapOf(), medicineListAtcClassificationsFilter), medicineListSort)
      allMedicines.clear()

      val newUiState = either {
        medicineRepository
          .getMedicinesForSpeciality()
          .map { medicines ->
            allMedicines.addAll(medicines)

            val atcClassificationFilterOptions: Map<String?, List<Pair<String, String>>> = medicines
              .mapNotNull { it.atcClassification.secondLevel }
              .groupBy { it.firstLevel?.name?.value }
              .mapValues { (_, secondLevels) ->
                secondLevels.map { it.code.value to it.name.value }
              }

            UiState.MedicineList(
              medicines = this@MedicineListViewModelImpl.sortedMedicines,
              filter = Filter(medicineListSearchInputFilter, atcClassificationFilterOptions, medicineListAtcClassificationsFilter),
              sort = medicineListSort
            )
          }
          .bind()
      }.fold(
        {
          UiState.Error(Filter(medicineListSearchInputFilter, mapOf(), medicineListAtcClassificationsFilter), medicineListSort)
        },
        {
          it
        }
      )

      uiState.value = newUiState
    }
  }

  override fun updateSearchInput(searchInput: String?) {
    App.scope.launch {
      medicineListSearchInputFilter.value = searchInput

      filteredMedicines.clear()
      filteredMedicines.addAll(filterMedicines(allMedicines.toList(), searchInput, medicineListAtcClassificationsFilter.toList()))
    }
  }

  override fun updateAtcClassificationFilter(codes: Set<AtcClassificationCode>) {
    App.scope.launch {
      medicineListAtcClassificationsFilter.clear()
      medicineListAtcClassificationsFilter.addAll(codes)

      filteredMedicines.clear()
      filteredMedicines.addAll(filterMedicines(allMedicines.toList(), medicineListSearchInputFilter.value, codes.toList()))
    }
  }

  override fun updateSort(sort: Sort) {
    App.scope.launch {
      medicineListSort.value = sort

      sortedMedicines.clear()
      sortedMedicines.addAll(sortMedicines(filteredMedicines.toList(), sort))
    }
  }

  private fun sortMedicines(
    medicines: List<MedicineSummaryModel>,
    sort: Sort
  ): List<MedicineSummaryModel> =
    when (sort) {
      Sort.Default -> medicines.filter { it.isPartner } + medicines.filterNot { it.isPartner }.sortedBy { it.name.value }
      Sort.Alphabetical -> medicines.sortedBy { it.name.value }
      Sort.AtcClassificationCode -> medicines.sortedBy { it.atcClassification.code.value }
    }


  private fun filterMedicines(
    medicines: List<MedicineSummaryModel>,
    searchInput: String?,
    atcClassifications: List<AtcClassificationCode>
  ): List<MedicineSummaryModel> =
    medicines
      .filter { medicine ->
        val searchInputMatch = searchInput?.let {
          medicine.name.value.containsNormalized(it, true) || medicine.atcClassification.name.value.containsNormalized(it, true)
        } ?: true

        val atcClassificationMatch = if (atcClassifications.isNotEmpty())
          medicine.atcClassification.secondLevel?.code in atcClassifications
        else
          true

        searchInputMatch && atcClassificationMatch
      }
}
