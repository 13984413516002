package fr.labodoc.webapp.pages.admin.medicines.view

import fr.labodoc.app.data.admin.model.AtcClassificationModel
import fr.labodoc.app.data.admin.model.MedicalCardTypeModel
import fr.labodoc.app.data.admin.model.MedicalProfessionModel
import fr.labodoc.app.data.admin.model.ProfessionalStatusModel
import fr.labodoc.domain.labodoc.common.Segmentation
import fr.labodoc.domain.labodoc.laboratory.LaboratoryId
import fr.labodoc.domain.labodoc.laboratory.LaboratoryName
import fr.labodoc.domain.labodoc.laboratory.LaboratoryPharmacovigilance
import fr.labodoc.domain.labodoc.marketingdocument.MarketingDocumentId
import fr.labodoc.domain.labodoc.marketingdocument.MarketingDocumentName
import fr.labodoc.domain.labodoc.medicine.CisCode
import fr.labodoc.domain.labodoc.medicine.DosageName
import fr.labodoc.domain.labodoc.medicine.MedicineId
import fr.labodoc.domain.labodoc.medicine.MedicineName
import fr.labodoc.webapp.pages.admin.medicines.DosageForm
import fr.labodoc.webapp.pages.admin.medicines.MarketingDocumentForm
import io.ktor.http.*
import io.kvision.state.ObservableList
import io.kvision.state.ObservableState
import io.kvision.state.ObservableValue
import kotlinx.datetime.Instant

abstract class AdminMedicinePageViewModel {
  sealed class UiState {
    data object Loading : UiState()

    data object Error : UiState()

    data class Medicine(
      val medicine: AdminMedicinePageViewModel.Medicine,
      val laboratory: Laboratory,
      val marketingDocuments: MarketingDocuments,
      val dosages: Dosages,
      val medicalProfessions: Set<MedicalProfessionModel>,
      val professionalStatuses: Set<ProfessionalStatusModel>,
      val medicalCardTypes: Set<MedicalCardTypeModel>
    ) : UiState()

    data object MedicineDeleted : UiState()
  }

  data class Medicine(
    val id: MedicineId,
    val name: MedicineName,
    val atcClassification: AtcClassificationModel,
    val logoUrl: Url,
    val segmentation: Segmentation,
    val processing: ObservableValue<Boolean>,
    val deleteModalOpen: ObservableValue<Boolean>
  )

  data class Laboratory(
    val id: LaboratoryId,
    val name: LaboratoryName,
    val pharmacovigilance: LaboratoryPharmacovigilance?
  )

  data class MarketingDocuments(
    val document: ObservableList<MarketingDocument>,
    val processing: ObservableValue<Boolean>,
    val createModalOpen: ObservableValue<Boolean>
  )

  data class MarketingDocument(
    val id: MarketingDocumentId,
    val name: MarketingDocumentName,
    val segmentation: Segmentation,
    val documentUrl: Url,
    val expireAt: Instant?,
    val processing: ObservableValue<Boolean>,
    val editModalOpen: ObservableValue<Boolean>,
    val deleteModalOpen: ObservableValue<Boolean>
  )

  data class Dosages(
    val dosages: ObservableList<Dosage>,
    val processing: ObservableValue<Boolean>,
    val createModalOpen: ObservableValue<Boolean>
  )

  data class Dosage(
    val cisCode: CisCode,
    val name: DosageName,
    val processing: ObservableValue<Boolean>,
    val deleteModalOpen: ObservableValue<Boolean>
  )

  abstract val uiState: ObservableState<UiState>
  abstract val errorMessage: ObservableState<String?>
  abstract val successMessage: ObservableState<String?>

  abstract fun refresh()

  abstract fun addMarketingDocument(form: MarketingDocumentForm)

  abstract fun updateMarketingDocument(marketingDocument: MarketingDocument, form: MarketingDocumentForm)

  abstract fun deleteMarketingDocument(marketingDocument: MarketingDocument)

  abstract fun addDosage(dosageForm: DosageForm)

  abstract fun deleteDosage(dosage: Dosage)

  abstract fun deleteMedicine()
}
